import { Text, Div, Input, Icon } from "atomize";
import { useContext, useState } from "react";
import Lang from "../../helpers/Text/Lang"
import PrimaryBtn from "../PrimaryBtn/PrimaryBtn";
import { LangVarContext } from "../../App";
import { useLogSnag } from "@logsnag/react";
import { useParams } from "react-router-dom";

const Subscribe: React.FC<{ module: string, cta_text?: string }> = ({ module, cta_text }) => {
  const { langVar } = useContext(LangVarContext)
  const { track } = useLogSnag()
  const { moduleName, productName } = useParams<{ moduleName?: string, productName?: string }>()

  const [email, setEmail] = useState<string>('')
  const [isValidEmail, setIsValidEmail] = useState<boolean>(true)
  const [isSubscribed, setIsSubscribed] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const emailSubscribe = async () => {
    if (!isLoading) {
      if (validateEmail(email)) {
        setIsValidEmail(true)
        setIsLoading(true)
        let page
        let eventType = "general"
        if (moduleName) {
          if(moduleName.endsWith('transporters')) {
            eventType = "transporter"
            page = Lang('EN').modules_items.transporters.find(item => item.type === moduleName)
          } else {
            eventType = "shipper"
            page = Lang('EN').modules_items.shippers.find(item => item.type === moduleName)
          }
        }
        if (productName) {
          page = Lang('EN').packages_items.find(item => item.type === productName)
        }

        try {
          const res = await fetch('https://xrlz-v0hw-be7b.n7c.xano.io/api:Qw8weFkU/cargobite-email-sub', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                email,
                module,
            })
          })
          const data = await res.json()
          track({
            channel: "request-invite",
            event: "Invite request signup",
            user_id: email,
            description: `User ${email} requested an invite on page: ${page?.name ?? 'Cargobite Landing Page'}`,
            icon: "🔔",
            notify: true,
            tags: {
              page: page?.name || 'Cargobite Landing Page',
              type: eventType
            }
          })
          setIsLoading(false)
          setIsSubscribed(true)
        } catch(error) {
          console.error('Error', error)
          setIsLoading(false)
        }
      } else {
        setIsValidEmail(false)
      }
    }
    
}

const validateEmail = (email: string) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return regex.test(email)
}

  return (
    <>
      {isSubscribed && (
        <Div d="flex" flexDir="row" align="center" w="100%">
          <Icon name="Checked" size="20px" color="success" m={{ r: "0.25rem"}} />
          <Text textSize={16} textColor="success" >
            {Lang(langVar).subscribe_success}
          </Text>
        </Div>
      )}
      {!isSubscribed && (
        <>
          {isValidEmail ? null : <Text textSize="caption" textColor="danger">
            {Lang(langVar).invalid_email}
          </Text>}
          <Div d="flex" flexDir={{ xs: "column", md: "row" }} w="100%" align={{ xs: "stretch", md: "flex-start" }}>
            <Input
              placeholder={Lang(langVar).enter_email}
              name="email"
              value={email}
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)}
              textSize={"16"}
              type="email"
              h="48px"
              m={{ r: { xs: "0rem", md: "0.5rem" }, b:"0.25rem"}} 
              w={{ xs: "100%", md: "235px" }}
              error={!isValidEmail ? Lang(langVar).invalid_email : ""}
              borderColor={!isValidEmail ? "danger" : undefined}
            />
            <PrimaryBtn
              handleSubmit={
                  (e: React.ChangeEvent<HTMLButtonElement>) => emailSubscribe()
              }
              isLoading={false}
              disabled={false}
              m={{ r: "0.5rem", b:"0.25rem"}} 
              w={{ xs: "100%", md: "180px" }}
              text={cta_text ?? Lang(langVar).btn.request_invite}
            />
          </Div>
        </>
      )}
    </>
)}

export default Subscribe