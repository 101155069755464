import { Helmet } from 'react-helmet';
import React, { useContext } from 'react'
import inbound_sales_ai from "../../../assets/images/inbound_sales_ai.png"
import recognition_inbound from "../../../assets/images/recognition_inbound.png"
import { Text, Div } from "atomize";
import usePreloadImages from '../../../helpers/CustomHooks/usePreloadImages';
import useWindowDimensions from '../../../helpers/CustomHooks/useWindowDimensions';
import Lang from '../../../helpers/Text/Lang';
import Border from '../../../components/Border/Border';
import { useTheme } from 'styled-components';
import Subscribe from '../../../components/Subscribe/Subscribe';
import { LangVarContext } from '../../../App';
import { Link } from 'react-router-dom';

const InboundSalesAI: React.FC<{}> = () => {
    const { langVar } = useContext(LangVarContext)
    usePreloadImages(false)
    const { width } = useWindowDimensions()
    const theme = useTheme()

    return (
        <>
            <Helmet>
                <title>Inbound Sales AI Tool for Transporters in Logistics | Cargobite</title>
                <meta content="Inbound Sales AI Tool for Transporters in Logistics | Cargobite" property="og:title" />
                <meta content="Inbound Sales AI Tool for Transporters in Logistics | Cargobite" name="twitter:title" />
                <meta content="Leverage the power of AI to transform incoming transport requests into accurate, instant price estimates, optimizing your sales cycle and enhancing shipper communication." name="description" />
                <meta content="Leverage the power of AI to transform incoming transport requests into accurate, instant price estimates, optimizing your sales cycle and enhancing shipper communication." property="og:description" />
                <meta content="Leverage the power of AI to transform incoming transport requests into accurate, instant price estimates, optimizing your sales cycle and enhancing shipper communication." name="twitter:description" />
            </Helmet>
            <Div>
                <Text tag="h3" textSize="tiny" textAlign="left"  textTransform="uppercase" textColor="success" textWeight="700" width={width} m={{ t: width > 700 ? "4rem" : "1.5rem" }}>
                    {Lang(langVar).transporters.sales.label}
                </Text>
                <Text tag="h1" textAlign="left" textSize={{ xs: "display2", md: "display3" }} textWeight="700" textColor="dark" m={{ t: width > 700 ? "1rem" : "0.25rem" }}>
                    {Lang(langVar).transporters.sales.title}
                </Text>
                <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                    {Lang(langVar).transporters.sales.subtitle}
                </Text>

                <Subscribe module={Lang('EN').transporters.sales.label} />
            </Div>

            <img src={inbound_sales_ai} style={{ width: width > 600 ? "calc(100% + 32rem)" : "calc(100% + 14rem)", margin: width > 600 ? "7.75rem -6rem 0" : "3.75rem -7rem 0"}} alt="Cargobite Inboud Sales AI for Transporters image" />
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).transporters.sales.revolutionize_title} </Text>
                <Box width={width} title={Lang(langVar).transporters.sales.analysis_title} text={Lang(langVar).transporters.sales.analysis_body}/>
                <Box width={width} title={Lang(langVar).transporters.sales.calculation_title} text={Lang(langVar).transporters.sales.calculation_body}/>
                <Box width={width} title={Lang(langVar).transporters.sales.communication_title} text={Lang(langVar).transporters.sales.communication_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).transporters.sales.enhance_title} </Text>
                <Box width={width} title={Lang(langVar).transporters.sales.pricing_title} text={Lang(langVar).transporters.sales.pricing_body}/>
                <Box width={width} title={Lang(langVar).transporters.sales.integration_title} text={Lang(langVar).transporters.sales.integration_body}/>
                <Box width={width} title={Lang(langVar).transporters.sales.insights_title} text={Lang(langVar).transporters.sales.insights_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}></Div>
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: width > 700 ? "4rem" : "2rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <img src={recognition_inbound} style={{ width: "100%", margin: "4rem 0 1rem" }} alt="Cargobite Relay Bundle image" />

            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="semiDark" textWeight="700"> {Lang(langVar).bundle} </Text>
                <Text tag="h3" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.5rem" }}>
                    {Lang(langVar).transporters.sales.bundle.first}
                    <Link to="/module/order-recognition-for-transporters" style={{ color: theme?.colors?.brand600 }}> {Lang(langVar).transporters.sales.bundle.recognition}</Link>
                    {Lang(langVar).transporters.sales.bundle.last}
                </Text>
                <Link
                    to="/product/relay"
                    style={{ marginTop: width > 700 ? "1rem" : "0.5rem" }}
                >
                    <Text
                        textSize={{ xs: "body", md: 16 }}
                        textColor="brand600"
                        hoverTextColor="brand700"
                        textWeight="700"
                        style={{ transition: "color 0.3s ease-in-out" }}
                    >
                        {Lang(langVar).transporters.sales.bundle_btn}
                    </Text>
                </Link>
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Text tag="h4" textAlign="left" textSize={{ xs: "display1", md: "display3" }} textWeight="700" textColor="dark" m={{ t: "4rem" }}>
                {Lang(langVar).transporters.sales.cta}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).transporters.sales.cta_body}
            </Text>

            <Subscribe module={Lang('EN').transporters.sales.label} />

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>
        </>
    )
}

export default InboundSalesAI

const Box: React.FC<{ width: number, title: string, text: string}> = ({ width, title, text }) => {

    return (
        <Div m={{ t: width > 700 ? "4rem" : "2rem" }}>
            <Text tag="h3" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500" m={{ b: "1rem" }}>
                    {title}
            </Text>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
                {text}
            </Text>
        </Div>
    )
}