const en = {
  label: "slot scheduling for transporters",
  title: "Maximize Efficiency with Cargobite's Slot Scheduling for Transporters",
  subtitle: "Unlock precise scheduling for pickups and drop-offs, allowing you to plan more effectively and eliminate unnecessary downtime for your drivers.",
  precision_title: "Precision Planning for Every Transport",
  allocation_title: "Accurate Slot Allocation",
  allocation_body: "Assign exact times for pickups and drop-offs, ensuring your fleet operates at peak efficiency. Our platform allows for meticulous planning, enabling you to use your resources wisely and keep your operations tight.",
  capabilities_title: "Advanced Scheduling Capabilities",
  capabilities_body: "Benefit from a scheduling system that’s designed for the dynamic needs of transporters. Whether you’re planning weeks in advance or adjusting for same-day changes, our tool adapts to your needs, helping you stay agile.",
  streamline: "Streamline Operations and Reduce Wait Times",
  eliminate_title: "Eliminate Driver Downtime",
  eliminate_body: "With precise scheduling, your drivers spend less time waiting and more time moving. This efficiency not only boosts morale but also significantly cuts operational costs related to wasted time and fuel.",
  coordination_title: "Enhanced Coordination with Partners",
  coordination_body: "Share your scheduling details with logistics partners and clients, ensuring everyone is on the same page. This level of coordination fosters stronger relationships and reduces the likelihood of miscommunication and delays.",
  leverage: "Leverage Data for Continuous Improvement",
  reporting_title: "Insightful Reporting",
  reporting_body: "Track your scheduling efficiency and identify patterns that can lead to improved planning. Our platform provides you with the data you need to make informed decisions and continuously refine your operations.",
  feedback_title: "Feedback Integration",
  feedback_body: "Incorporate driver and client feedback into your scheduling process. This feedback loop is invaluable for adjusting your approach and enhancing satisfaction across the board.",
  cta: "Transform Your Transport Scheduling with Cargobite",
  cta_body: "Step into the future of transport logistics with Cargobite’s Slot Scheduling. This tool is more than just a scheduler; it’s a way to revolutionize how you plan, execute, and improve your transport operations. Say goodbye to wasted time and hello to optimized efficiency."
}
const si = {
  label: "časovno načrtovanje reže za prevoznike",
  title: "Povečajte učinkovitost s Cargobitejevim režimskim načrtovanjem za prevoznike",
  subtitle: "Odklenite natančno načrtovanje prevzemov in dostav, kar vam omogoča bolj učinkovito načrtovanje in odpravo nepotrebnega mirovanja vaših voznikov.",
  precision_title: "Natančno načrtovanje za vsak prevoz",
  allocation_title: "Natančna dodelitev rež",
  allocation_body: "Določite natančne čase za prevzeme in dostave, zagotavljajoč, da vaša flota deluje pri največji učinkovitosti. Naša platforma omogoča skrbno načrtovanje, kar vam omogoča modro uporabo vaših virov in ohranjanje tesnih operacij.",
  capabilities_title: "Napredne zmogljivosti načrtovanja",
  capabilities_body: "Izkoristite načrtovanje, ki je zasnovano za dinamične potrebe prevoznikov. Ali načrtujete tedne vnaprej ali prilagajate spremembam istega dne, naše orodje prilagaja vašim potrebam, kar vam pomaga ostati agilnega.",
  streamline: "Optimizirajte operacije in zmanjšajte čakalne čase",
  eliminate_title: "Odpravite čas mirovanja voznikov",
  eliminate_body: "Z natančnim načrtovanjem vaši vozniki preživijo manj časa čakanja in več časa premikanja. Ta učinkovitost ne samo povečuje moralno, ampak tudi znatno zmanjšuje operativne stroške, povezane s zapravljenim časom in gorivom.",
  coordination_title: "Povečana usklajenost s partnerji",
  coordination_body: "Delite podrobnosti vašega načrtovanja s partnerskimi logističnimi podjetji in strankami, zagotavljajoč, da je vsakdo na isti strani. Ta raven koordinacije krepi močnejše odnose in zmanjšuje možnost nesporazumov in zamud.",
  leverage: "Izkoristite podatke za stalno izboljševanje",
  reporting_title: "Poglobljeno poročanje",
  reporting_body: "Sledite učinkovitosti vašega načrtovanja in identificirajte vzorce, ki lahko vodijo v izboljšano načrtovanje. Naša platforma vam zagotavlja potrebne podatke za informirane odločitve in stalno izboljševanje vaših operacij.",
  feedback_title: "Vključitev povratnih informacij",
  feedback_body: "Vključite povratne informacije voznikov in strank v vaš postopek načrtovanja. Ta povratna zanka je neprecenljiva za prilagajanje vašega pristopa in izboljšanje zadovoljstva na vseh ravneh.",
  cta: "Transformirajte vaše načrtovanje prevoza s Cargobitejem",
  cta_body: "Stopite v prihodnost logistike prevoza s Cargobitejevim režimskim načrtovanjem. To orodje ni le enostavno orodje za načrtovanje; je način, kako revolucionirati, kako načrtujete, izvajate in izboljšujete vaše prevozne operacije. Pozdravite zapravljen čas in pozdravite optimizirano učinkovitost."
}

const langSlotSchedulingTransporters = { en, si }

export default langSlotSchedulingTransporters