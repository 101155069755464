const en = {
  label: "contractor marketplace for transporters",
  title: "Streamline your operations with Cargobite's Contractor Marketplace",
  subtitle: "Unlock a new level of efficiency in managing your transport orders. With Cargobite's Contractor Marketplace, effortlessly extend your reach by subcontracting parts of your orders to reliable partners.",
  simplify_title: "Simplify your subcontracting process",
  splitting_title: "Effortless splitting and subcontracting of orders",
  splitting_body: "Navigate capacity challenges with unparalleled ease. Our platform enables you to take any existing order, divide it into segments, and find subcontractors for each part. This feature ensures that every portion of the order journey is covered, without the need for manual coordination.",
  automated_title: "Automated matchmaking with the best subcontractors",
  automated_body: "Leveraging the power of automation, our system searches third-party platforms to match you with the best subcontractors for each segment of your order. This guarantees that you're always connected to the right partners, maximizing efficiency and reliability.",
  control_title: "Enhanced control and transparency",
  integration_title: "Direct integration of subcontractors into orders",
  integration_body: "Integrate subcontractors into your orders seamlessly. With Cargobite, attaching detailed subcontractor information to specific segments of an order is straightforward, keeping your operations organized and transparent.",
  empower_title: "Empower suppliers with confirmation capabilities",
  empower_body: "Strengthen trust with your suppliers by involving them in the subcontractor selection process. Our platform allows for easy review and confirmation of chosen subcontractors by your suppliers, enhancing collaboration and satisfaction.",
  cta: "Elevate your logistics services with Cargobite",
  cta_body: "Embrace the future of transport logistics with Cargobite's Contractor Marketplace. This innovative module is designed to make the process of subcontracting not just possible but incredibly easy, ensuring you can always meet demand, no matter the scale or complexity of the order. Split, select, and secure subcontractors with just a few clicks, and transform the way you manage your logistics operations today."
}
const si = {
  label: "tržnica izvajalcev za prevoznike",
  title: "Poenostavite svoje poslovanje z Cargobitejevo tržnico izvajalcev",
  subtitle: "Odklenite novo raven učinkovitosti pri upravljanju vaših transportnih naročil. Z Cargobitejevo tržnico izvajalcev enostavno razširite svoj doseg s podizvajanjem delov svojih naročil zanesljivim partnerjem.",
  simplify_title: "Poenostavite svoj postopek podizvajanja",
  splitting_title: "Brezhibno deljenje in podizvajanje naročil",
  splitting_body: "Premagajte izzive zmogljivosti s prerojenim lahkotnim načinom. Naša platforma vam omogoča, da vzamete obstoječe naročilo, ga razdelite na segmente in poiščete podizvajalce za vsak del. Ta funkcija zagotavlja, da je vsak del poti naročila pokrit, brez potrebe po ročni koordinaciji.",
  automated_title: "Avtomatiziran izbor najboljših podizvajalcev",
  automated_body: "S pomočjo moči avtomatizacije naš sistem išče tretje platforme, da vas poveže z najboljšimi podizvajalci za vsak segment vašega naročila. To zagotavlja, da ste vedno povezani z ustreznimi partnerji, kar povečuje učinkovitost in zanesljivost.",
  control_title: "Izboljšan nadzor in preglednost",
  integration_title: "Neposredna integracija podizvajalcev v naročila",
  integration_body: "Podizvajalce vključite v svoja naročila brezhibno. Z Cargobitejem je pritrjevanje podrobnih informacij o podizvajalcih na določene segmente naročila enostavno, kar ohranja vaše operacije organizirane in pregledne.",
  empower_title: "Okrepite dobavitelje s sposobnostmi potrditve",
  empower_body: "Okrepite zaupanje s svojimi dobavitelji, tako da jih vključite v postopek izbire podizvajalcev. Naša platforma omogoča enostaven pregled in potrditev izbranih podizvajalcev s strani vaših dobaviteljev, kar izboljšuje sodelovanje in zadovoljstvo.",
  cta: "Dvignite svoje logistične storitve z Cargobitejem",
  cta_body: "Sprejmite prihodnost transportne logistike z Cargobitejevo tržnico izvajalcev. Ta inovativni modul je zasnovan, da postopek podizvajanja ne bo samo mogoč, ampak tudi izjemno enostaven, zagotavljajoč, da lahko vedno zadovoljite povpraševanje, ne glede na obseg ali kompleksnost naročila. Razdelite, izberite in zagotovite podizvajalce z le nekaj kliki ter spremenite način upravljanja vaših logističnih operacij že danes."
}

const langContractorMarketplaceTransporters = { en, si }

export default langContractorMarketplaceTransporters