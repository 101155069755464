import React, { useContext, useEffect, useState } from 'react'
import { Div, Text, Anchor } from "atomize";
import Lang from '../../helpers/Text/Lang';
import { LangVarContext } from '../../App';

const Footer: React.FC<{}> = () => {
  const { langVar } = useContext(LangVarContext)

  const [currentYear, setCurrentYear] = useState<Number>(2023)
  useEffect(() => {
    const date = new Date()
    const year = date.getFullYear()
    setCurrentYear(year)
  }, [])

  return (
    <Div d="flex" flexDir={{ xs: "column", md: "row" }} align="center" justify="space-between" w="100%" p={{ x: "1rem", t: "1rem", b: "0.5rem" }} m={{ t: "-1rem" }}>
      <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
          Copyright © { currentYear } Cargobite d.o.o.
      </Text>
      <Div m={{ l: { xs: '0', md: 'auto' } }} d="flex" flexDir={{ xs: "column", md: "row" }} align="center">
          <Anchor href="https://www.linkedin.com/company/cargobite/" target="_blank" className='link' cursor="pointer" m={{ l: { xs: '0', md: '1rem' } }}>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark" hoverTextColor="dark">
              LinkedIn
            </Text>
          </Anchor>

          <Anchor href="mailto:info@cargobite.com" target="_blank" className='link' cursor="pointer" m={{ l: { xs: '0', md: '1rem' } }}>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark" hoverTextColor="dark">
              {Lang(langVar).get_in_touch}
            </Text>
          </Anchor>
          
      </Div>
  </Div>
  )
}

export default Footer
