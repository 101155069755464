import langLanding from "./langLanding"
import langDocumentIssuing from "./langDocumentIssuing"
import langFleetTracking from "./langFleetTracking"
import langInboundSales from "./langInboundSales"
import langOrderRecognition from "./langOrderRecognition"
import langContractorMarketplaceTransporters from "./langContractorMarketplaceTransporters"
import langOrderManagmentTransporters from "./langOrderManagmentTransporters"
import langSlotSchedulingTransporters from "./langSlotSchedulingTransporters"
import langContractorMarketplaceShippers from "./langContractorMarketplaceShippers"
import langOrderManagmentShippers from "./langOrderManagmentShippers"
import langSlotSchedulingShippers from "./langSlotSchedulingShippers"
import langTMS from "./langTMS"
import langRelay from "./langRelay"


const en = {
    get_in_touch: "Get in touch",
    log_in: "Log in",
    for_transporters: "for transporters",
    for_shippers: 'for shippers',
    subscribe_success: 'Your request has been sent. We’ll reach out within few hours.',
    packages: "Packages",
    packages_items: [
        {
            name: 'TMS',
            type: 'tms',
            modules_text: "Modules included",
            modules_list: "Order Management, Document Issuing, Invoicing, Fleet Tracking"
        },
        {
            name: "Relay",
            type: "relay",
            modules_text: "Modules included",
            modules_list: "Order Recognition, Inbound Sales AI, 3rd Party Integrations"
        }
    ],
    modules: "Modules",
    modules_items: {
        transporters: [
            {
                name: 'Order Management',
                type: 'order-management-for-transporters'
            },
            {
                name: 'Documents & Invoicing',
                type: 'document-issuing-for-transporters'
            },
            {
                name: 'Fleet Tracking',
                type: 'fleet-tracking-for-transporters'
            },
            {
                name: 'Contractor Marketplace',
                type: 'contractor-marketplace-for-transporters'
            },
            {
                name: 'Order Recognition',
                type: 'order-recognition-for-transporters'
            },
            {
                name: 'Slot Scheduling',
                type: 'slot-scheduling-for-transporters'
            },
            {
                name: 'Inbound Sales AI',
                type: 'inbound-sales-ai-for-transporters'
            }
        ],
        shippers: [
            {
                name: 'Order Management',
                type: 'order-management-for-shippers'
            },
            {
                name: 'Contractor Marketplace',
                type: 'contractor-marketplace-for-shippers'
            },
            {
                name: 'Slot Scheduling',
                type: 'slot-scheduling-for-shippers'
            },
        ]
    },
    pricing: "Pricing",
    bundle: "bundle package",
    create_account: "Request access",
    go_to_app: "Open App",
    enter_email: "Enter your email",
    invalid_email: "Invalid email format",
    btn: {
        create_company_account: "Request access",
        book_call: "Book a call",
        request_invite: "Request an invite",
    },
    landing: langLanding.en,
    tms: langTMS.en,
    relay: langRelay.en,
    transporters: {
        marketplace: langContractorMarketplaceTransporters.en,
        managment: langOrderManagmentTransporters.en,
        scheduling: langSlotSchedulingTransporters.en,
        documentation: langDocumentIssuing.en,
        tracking: langFleetTracking.en,
        sales: langInboundSales.en,
        recognition: langOrderRecognition.en
    },
    shippers: {
        marketplace: langContractorMarketplaceShippers.en,
        managment: langOrderManagmentShippers.en,
        scheduling: langSlotSchedulingShippers.en
    },
}

const si = {
    get_in_touch: "Kontakt",
    log_in: "Prijavite se",
    for_transporters: "za prevoznike",
    for_shippers: 'za pošiljatelje',
    subscribe_success: 'Vaša zahteva je bila poslana. Pisali vam bomo še danes.',
    packages: "Paketi",
    packages_items: [
        {
            name: 'TMS',
            type: 'tms',
            modules_text: "Vključeni moduli",
            modules_list: "Upravljanje naročil, Izdaja Dokumentov, Obračunavanje, Sledenje voznemu parku"
        },
        {
            name: "Relay",
            type: "relay",
            modules_text: "Vključeni moduli",
            modules_list: "Prepoznavanje naročil, Vhodna prodaja AI, Tretjeosebne integracije"
        }
    ],
    modules: "Moduli",
    modules_items: {
        transporters: [
            {
                name: "Upravljanje naročil",
                type: "order-management-for-transporters"
            },
            {
                name: "Dokumenti in obračunavanje",
                type: "document-issuing-for-transporters"
            },
            {
                name: "Sledenje voznemu parku",
                type: "fleet-tracking-for-transporters"
            },
            {
                name: "Tržnica izvajalcev",
                type: "contractor-marketplace-for-transporters"
            },
            {
                name: "Prepoznavanje naročil",
                type: "order-recognition-for-transporters"
            },
            {
                name: "Načrtovanje rež",
                type: "slot-scheduling-for-transporters"
            },
            {
                name: "Vhodna prodaja AI",
                type: "inbound-sales-ai-for-transporters"
            }
        ],
        shippers: [
            {
                name: "Upravljanje naročil",
                type: "order-management-for-shippers"
            },
            {
                name: "Tržnica izvajalcev",
                type: "contractor-marketplace-for-shippers"
            },
            {
                name: "Načrtovanje rež",
                type: "slot-scheduling-for-shippers"
            }
        ]
    },
    pricing: "Cene",
    bundle: "skupinski paketi",
    create_account: "Zahtevajte dostop",
    go_to_app: "Odprite aplikacijo",
    enter_email: "Vnesite svoj email",
    invalid_email: "Nepravilen email format",
    btn: {
        create_company_account: "Zahtevajte dostop",
        book_call: "Rezervirajte klic",
        request_invite: "Zahtevajte povabilo",
    },
    landing: langLanding.si,
    tms: langTMS.si,
    relay: langRelay.si,
    transporters: {
        marketplace: langContractorMarketplaceTransporters.si,
        managment: langOrderManagmentTransporters.si,
        scheduling: langSlotSchedulingTransporters.si,
        documentation: langDocumentIssuing.si,
        tracking: langFleetTracking.si,
        sales: langInboundSales.si,
        recognition: langOrderRecognition.si
    },
    shippers: {
        marketplace: langContractorMarketplaceShippers.si,
        managment: langOrderManagmentShippers.si,
        scheduling: langSlotSchedulingShippers.si
    },
}

const Lang = (lang: "EN" | "SI") => {
    // const langVar = useReactiveVar(lang)

    switch (lang) {
        case "EN":
            return en
        case "SI":
            return si
        default:
            return en
    }

}

export default Lang