import { Helmet } from 'react-helmet';
import React, { useContext } from 'react'
import contractor_marketplace from "../../../assets/images/contractor_marketplace.png"
import { Text, Div } from "atomize";
import usePreloadImages from '../../../helpers/CustomHooks/usePreloadImages';
import useWindowDimensions from '../../../helpers/CustomHooks/useWindowDimensions';
import Lang from '../../../helpers/Text/Lang';
import Border from '../../../components/Border/Border';
import Subscribe from '../../../components/Subscribe/Subscribe';
import { LangVarContext } from '../../../App';

const ContractorMarketplace: React.FC<{}> = () => {
    const { langVar } = useContext(LangVarContext)
    usePreloadImages(false)
    const { width } = useWindowDimensions()

    return (
        <>
            <Helmet>
                <title>Contractor Marketplace Tool for Shippers in Logistics | Cargobite</title>
                <meta content="Contractor Marketplace Tool for Shippers in Logistics | Cargobite" property="og:title" />
                <meta content="Contractor Marketplace Tool for Shippers in Logistics | Cargobite" name="twitter:title" />
                <meta content="Elevate your transport procurement process with the Contractor Marketplace, designed for shippers to effortlessly find the best transport offers, ensuring both efficiency and reliability in your logistics chain." name="description" />
                <meta content="Elevate your transport procurement process with the Contractor Marketplace, designed for shippers to effortlessly find the best transport offers, ensuring both efficiency and reliability in your logistics chain." property="og:description" />
                <meta content="Elevate your transport procurement process with the Contractor Marketplace, designed for shippers to effortlessly find the best transport offers, ensuring both efficiency and reliability in your logistics chain." name="twitter:description" />
            </Helmet>
            <Div>
                <Text tag="h3" textSize="tiny" textAlign="left"  textTransform="uppercase" textColor="warning" textWeight="700" width={width} m={{ t: width > 700 ? "4rem" : "1.5rem" }}>
                    {Lang(langVar).shippers.marketplace.label}
                </Text>
                <Text tag="h1" textAlign="left" textSize={{ xs: "display2", md: "display3" }} textWeight="700" textColor="dark" m={{ t: width > 700 ? "1rem" : "0.25rem" }}>
                    {Lang(langVar).shippers.marketplace.title}
                </Text>
                <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                    {Lang(langVar).shippers.marketplace.subtitle}
                </Text>

                <Subscribe module={Lang('EN').shippers.marketplace.label} />
            </Div>

            <img src={contractor_marketplace} style={{ width: width > 600 ? "calc(100% + 32rem)" : "calc(100% + 14rem)", margin: width > 600 ? "7.75rem -6rem 0" : "3.75rem -7rem 0"}} alt="Cargobite Contractor Marketplace for Shippers image" />
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).shippers.marketplace.access_title} </Text>
                <Box width={width} title={Lang(langVar).shippers.marketplace.receive_title} text={Lang(langVar).shippers.marketplace.receive_body}/>
                <Box width={width} title={Lang(langVar).shippers.marketplace.selection_title} text={Lang(langVar).shippers.marketplace.selection_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>

            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).shippers.marketplace.decisions_title} </Text>
                <Box width={width} title={Lang(langVar).shippers.marketplace.reviews_title} text={Lang(langVar).shippers.marketplace.reviews_body}/>
                <Box width={width} title={Lang(langVar).shippers.marketplace.history_title} text={Lang(langVar).shippers.marketplace.history_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                </Div>
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: width > 700 ? "4rem" : "2rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Text tag="h4" textAlign="left" textSize={{ xs: "display1", md: "display3" }} textWeight="700" textColor="dark" m={{ t: "4rem" }}>
                {Lang(langVar).shippers.marketplace.cta}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).shippers.marketplace.cta_body}
            </Text>

            <Subscribe module={Lang('EN').shippers.marketplace.label} />

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>
        </>
    )
}

export default ContractorMarketplace

const Box: React.FC<{ width: number, title: string, text: string}> = ({ width, title, text }) => {

    return (
        <Div m={{ t: width > 700 ? "4rem" : "2rem" }}>
            <Text tag="h3" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500" m={{ b: "1rem" }}>
                    {title}
            </Text>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
                {text}
            </Text>
        </Div>
    )
}