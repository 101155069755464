const en = {
  label: "document issuing for transporters",
  title: "Simplify your document workflow with Cargobite's Documents Module",
  subtitle: "Discover how our Documents Module revolutionizes paperwork management, from invoicing to compliance, making every step smoother for transporters.",
  automate_title: "Automate and streamline document management",
  invoice_title: "Effortless invoice issuing",
  invoice_body: "Automate your invoicing process with ease. Our system not only generates invoices but also customizes them with the appropriate VAT percentages and clauses, depending on the destination.",
  seamless_title: "Retrieval of all relevant documentation",
  seamless_body: "Receive and manage CMR/DDT documents automatically, saving time and reducing errors in your transport documentation. Emit CMR documents when needed.",
  estimated_title: "Estimated payment dates",
  estimated_body: "Stay informed about your cash flow. Our system provides estimates of when payments will be received, based on the due dates of your invoices, helping you plan your finances more effectively.",
  enhance_title: "Enhance compliance and integration",
  vat_title: "Automated VAT and clauses addition",
  vat_body: "Ensure compliance without the headache. Link up your invoices with order documentation and all companies involved in your transport operation.",
  link_title: "Link-up with 3rd party accounting systems",
  link_body: "Simplify your accounting process. Our module integrates seamlessly with existing accounting systems, allowing for easy export and management of financial documents.",
  communicate_title: "Communicate and share with confidence",
  bulk_title: "Bulk invoice management",
  bulk_body: "Manage and send invoices in bulk efficiently. Our system enables you to handle multiple invoices at once, streamlining your billing process.",
  private_title: "Private link sharing with clients",
  private_body: "Share documents securely and privately. Distribute invoices and important documents to your clients through private links, enhancing the professionalism and efficiency of your communications.",
  bundle: {
    first: "Use document issuing module as standalone application or bundle it together with ",
    last: " modules in our TMS product.",
    document: "order management",
    fleet: "fleet tracking"
  },
  bundle_btn: "View TMS product →",
  cta: "Revolutionize your document management",
  cta_body: "Join Cargobite today to leverage the Documents Module designed specifically for transporters. Say goodbye to manual document handling and embrace automated, compliant, and efficient document management. With Cargobite, your paperwork is as mobile as your fleet, moving your business forward with every mile."
}
const si = {
  label: "izdajanje dokumentov za prevoznike",
  title: "Počistite svoj delovni tok dokumentov z modulom Documents Cargobite",
  subtitle: "Odkrijte, kako naš modul Documents revolucionira upravljanje dokumentov, od izdajanja računov do skladnosti, kar vsak korak olajša prevoznikom.",
  automate_title: "Avtomatizirajte in poenostavite upravljanje dokumentov",
  invoice_title: "Enostavno izdajanje računov",
  invoice_body: "Samodejno avtomatizirajte svoj postopek izdajanja računov. Naš sistem ne samo generira račune, ampak jih tudi prilagodi z ustreznimi stopnjami DDV in klavzulami, odvisno od destinacije.",
  seamless_title: "Pridobivanje vseh relevantnih dokumentov",
  seamless_body: "Prejmite in upravljajte dokumente CMR/DDT avtomatično, kar prihrani čas in zmanjšuje napake v vaši transportni dokumentaciji. Izdajte dokumente CMR po potrebi.",
  estimated_title: "Predvideni datumi plačil",
  estimated_body: "Ostanite obveščeni o svojem denarnem toku. Naš sistem zagotavlja ocene, kdaj bodo plačila prejeta, glede na roke plačil vaših računov, kar vam pomaga načrtovati svoje finance bolj učinkovito.",
  enhance_title: "Izboljšajte skladnost in integracijo",
  vat_title: "Avtomatizirano dodajanje DDV in klavzul",
  vat_body: "Zagotovite skladnost brez glavobola. Povežite svoje račune z dokumentacijo naročil in vsemi podjetji, vključenimi v vaše transportno delovanje.",
  link_title: "Povezava s sistemi računovodstva tretjih oseb",
  link_body: "Poenostavite svoj računovodski proces. Naš modul se brezhibno integrira z obstoječimi sistemi računovodstva, kar omogoča enostaven izvoz in upravljanje finančnih dokumentov.",
  communicate_title: "Komunicirajte in delite s samozavestjo",
  bulk_title: "Upravljanje večjih količin računov",
  bulk_body: "Učinkovito upravljajte in pošiljajte račune v večjih količinah. Naš sistem vam omogoča, da hkrati obdelate več računov, kar poenostavi vaš postopek zaračunavanja.",
  private_title: "Deljenje zasebnih povezav s strankami",
  private_body: "Delite dokumente varno in zasebno. Svojim strankam razdelite račune in pomembne dokumente prek zasebnih povezav, kar izboljšuje profesionalnost in učinkovitost vašega komuniciranja.",
  bundle: {
    first: "Uporabite modul za izdajanje dokumentov kot samostojno aplikacijo ali ga združite s moduli za ",
    last: " v našem izdelku TMS.",
    document: "upravljanje naročil",
    fleet: "sledenje vozil"
  },
  bundle_btn: "Ogled izdelka TMS →",
  cta: "Revolucionirajte svoje upravljanje dokumentov",
  cta_body: "Pridružite se Cargobite danes, da izkoristite modul Documents, zasnovan posebej za prevoznike. Poslovite se od ročnega ravnanja z dokumenti in sprejmite avtomatizirano, skladno in učinkovito upravljanje dokumentov. Z Cargobitejem je vaša dokumentacija enako mobilna kot vaša flota, premika vaše poslovanje naprej s vsakim kilometrom."
}

const langDocumentIssuing = { en, si }

export default langDocumentIssuing