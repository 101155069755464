import { Helmet } from 'react-helmet';
import React from 'react'
import { useHistory } from 'react-router'
import { Text, Div } from "atomize";
import renderIcon from '../../assets/Icons';
import PrimaryBtn from '../../components/PrimaryBtn/PrimaryBtn';
import useWindowDimensions from '../../helpers/CustomHooks/useWindowDimensions';

const Page404: React.FC = () => {
    let history = useHistory();
    const { height } = useWindowDimensions()

    return (
        <>
            <Helmet>
                <title>Cargobite | Page not found</title>
                <meta content="Cargobite | Page not found" property="og:title" />
                <meta content="Cargobite | Page not found" name="twitter:title" />
                <meta content="Discover how our cutting-edge features can help you streamline your processes, reduce costs, and boost efficiency across your entire supply chain." name="description" />
                <meta content="Discover how our cutting-edge features can help you streamline your processes, reduce costs, and boost efficiency across your entire supply chain." property="og:description" />
                <meta content="Discover how our cutting-edge features can help you streamline your processes, reduce costs, and boost efficiency across your entire supply chain." name="twitter:description" />
            </Helmet>
            <Div w="100%"
                flexDir="column"
                justify="center"
                align="center" minH={`${height}px`} d="flex"
            >
                {renderIcon("Logo")}
                <Text textSize={100} textWeight={600} m={{ t: "2rem" }}>
                    404
                </Text>
                <Text textSize={20} textColor="semiLight" textWeight={500} m={{ b: "1rem" }}>
                    Page not found
                </Text>
                <PrimaryBtn
                    h="48px"
                    w="100%"
                    styleBtn={{ marginTop: "1.5rem", maxWidth: "334px" }}
                    handleSubmit={() => history.push("/")}
                    text={"Go back"}
                />
            </Div>
        </>
    )
}

export default Page404

