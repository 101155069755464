const en = {
  label: "cargobite landing page",
  title: "The collaborative logistics network.",
  subtitle: "Discover how our cutting-edge features can help you streamline your processes, reduce costs, and boost efficiency across your entire supply chain.",
  ending_title: "Take the next step towards logistics success.",
  ending_subtitle: "Join our waitlist today and be among the first to experience the benefits of our tools. Don't let outdated processes hold you back – embrace the future of logistics with Cargobite.",
  upgrade: {
      title: "Real-time order updates",
      label: "Connected logistics",
      sections_title: {
          visibility: "Complete order visibility.",
          communication: "Effortless communication.",
          orders: "Customizable order management.",
      },
      sections_text: {
          visibility: "Experience seamless communication and total transparency between all partners involved in your supply chain. Our platform provides real-time shipment tracking and live confirmations, ensuring everyone stays informed and up-to-date on the status of your orders.",
          communication: "Say goodbye to time-consuming emails and phone calls. Our live chat feature allows you to instantly sync up with your partners on operational processes, while our dispatcher assignment feature ensures smooth communication directly from the platform.",
          orders: "Navigate and organize your orders with ease using our clean and intuitive interface. With filters, search, and grouping options, you can create a custom view to monitor your orders and stay organized.",
      },
  },
  boost: {
      title: "Expand your partner network.",
      label: "Seamless sourcing",
      sections_title: {
          quotes: "Instant market quotes.",
          partners: "Connect with reliable partners.",
          marketplace: "Open marketplace with trusted ratings.",
      },
      sections_text: {
          quotes: "Get competitive quotes for all your orders with just a few clicks. Reduce your transport costs and choose the quality of service that best fits your needs.",
          partners: "Cargobite creates a network that connects suppliers with trustworthy logistics contractors, allowing you to easily find and engage with the best partners for your needs.",
          marketplace: "Enter our open market where every provider has a community-sourced rating you can rely on. Pick a provider that can execute your order based on your requirements.",
      },
  },
  scale: {
      title: "Enterprise-grade integration.",
      label: "Centralized operations",
      sections_title: {
          hub: "One-stop operations hub.",
          data: "Data-driven decision making.",
          customization: "Customization and flexibility.",
      },
      sections_text: {
          hub: "Our platform integrates with existing or custom systems and tools used in the logistics industry, such as ERP, accounting, and inventory management systems. This not only improves efficiency but also reduces the chances of errors due to manual data entry.",
          data: "Make impactful decisions based on data from your ongoing operations. Our platform provides structured shipping and partner insights that help you reduce costs and boost efficiency across your team.",
          customization: "Cargobite is designed to accommodate the unique needs of each organization. With custom fields, forms, and reports, our platform can be tailored to fit your specific workflows and processes.",
      },
  },
  pricing: {
      title: "Get discounted pricing rate during our beta phase until the end of 2024. Join now and lock your discount for 2 years.",
      label: "Pricing",
  },
}

const si = {
  label: "cargobite pristajalna stran",
  title: "Kolaborativno logistično omrežje.",
  subtitle: "Spoznajte kako vam lahko naša vrhunska orodja pomagajo poenostaviti procese, zmanjšati stroške in povečati  učinkovitost vaše logistike.",
  ending_title: "Naredite naslednji korak proti uspehu v logistiki vašega podjetja.",
  ending_subtitle: "Pridružite se čakalni listi danes in bodite med prvimi, ki bodo izkusili koristi naših orodji. Ne dovolite, da vas zastareli procesi omejijo - sprejmite prihodnost logistike in preizkusite Cargobite.",
  upgrade: {
      title: "Revolucionirajte poslovanje z digitalno preobrazbo.",
      label: "Povezana logistika",
      sections_title: {
          visibility: "Popolna preglednost transportnih naročil.",
          communication: "Tekoča komunikacija.",
          orders: "Prilagodljivo upravljanje naročil.",
      },
      sections_text: {
          visibility: "Doživite brezhibno komunikacijo in popolno preglednost med vsemi partnerji v vaši dobavni ali prodajni verigi. Naša platforma zagotavlja sledenje pošiljk v realnem času in posodobitve v živo, kar omogoča ažurno obveščanje vseh deležnikov o statusu vaših naročil.",
          communication: "Pozabite na nepotrebno dopisovanje in telefonske klice. Naša funkcija klepeta v živo vam omogoča takojšnjo sinhronizacijo z vašimi partnerji glede operativnih procesov. Spoznajte našo transparentno delitev odgovornosti, ki zagotavlja brezhibno komunikacijo neposredno preko platforme.",
          orders: "Uporabite naš prilagodljiv vmesnik za naročanje in organiziranje, ter si zagotovite enostaven in učinkovit nadzor nad vašimi naročili. S funkcijami filtrov, iskanja in združevanje lahko ustvarite prilagojen prikaz za spremljanje vaših naročil in ohranjanje organiziranosti.",
      },
  },
  boost: {
      title: "Upravljajte z vašimi partnerji ali preizkusite nove.",
      label: "Brezhibna nabava",
      sections_title: {
          quotes: "Brezskrbno pridobivanje ponudb.",
          partners: "Povežite se z zanesljivimi partnerji.",
          marketplace: "Odprti trg za prevozne storitve z zaupanja vrednimi ocenami ponudnikov.",
      },
      sections_text: {
          quotes: "Z le nekaj kliki pridobite konkurenčne ponudbe za vaša naročila ali dodelite transporte vašim dobaviteljem. Zmanjšajte prevozne stroške in izberite kakovost storitve, ki najbolj ustreza vašim potrebam.",
          partners: "Cargobite ponuja mrežo, ki povezuje naročnike prevozov z zanesljivimi izvajalci logističnih storitev in omogoča enostavno sodelovanje z najboljšimi partnerji za vaše potrebe.",
          marketplace: "Vstopite na naš odprti trg, kjer ima vsak ponudnik oceno, ki je generirana s pomočjo ostalih uporabnikov in se na njo lahko 100% zanesete. Izberite preverjenega ponudnika, ki bo izpolnil vaše zahteve in izvedel vaše naročilo v skladu z željami in zahtevami.",
      },
  },
  scale: {
      title: "Intregrirajte logistiko v vse procese.",
      label: "Centralizirane operacije",
      sections_title: {
          hub: "Centralno mesto za upravljanje operacij.",
          data: "Odločanje na podlagi podatkov.",
          customization: "Prilagodljivost in prilagojenost.",
      },
      sections_text: {
          hub: "Naša platforma se integrira z obstoječim ERP, računovodskimi ali skladiščnimi sistemi, ki jih uporablja naročnik. S tem se ne izboljša le učinkovitost, ampak se zmanjša tudi možnost napak zaradi ročnega vnosa podatkov.",
          data: "Sprejemajte pomembne odločitve na podlagi podatkov iz vaših tekočih operacij. Naša platforma zagotavlja strukturirane informacije o vaši logistiki in partnerjih, ter vam s tem pomaga zmanjšati stroške, ter povečati učinkovitost vaše ekipe.",
          customization: "Cargobite se prilagaja edinstvenim potrebam vsake organizacije. Z uporabo prilagodljivih polj, obrazcev in poročil se lahko naša platforma prilagodi vašim specifičnim delovnim tokom in procesom.",
      },
  },
  pricing: {
      title: "Dobite znižano ceno med našo beta fazo do konca leta 2024. Pridružite se zdaj in zaklenite svoj popust za 2 leti.",
      label: "Cenik",
  },
}

const langLanding = { en, si }

export default langLanding