import React from 'react'
import * as Styled from "./Border.styles"

interface BorderProps {
    color?: string
    margin?: string
    direction?: string
}

const Border: React.FC<BorderProps> = ({ color, margin, direction = "horizontal" }) => {
    return (
        <Styled.Border color={color} margin={margin} direction={direction}>
        </Styled.Border>
    )
}

export default Border
