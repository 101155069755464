import React from 'react'
import { Button, Icon } from "atomize";


const PrimaryBtn: React.FC<any> = ({ textSize = 14, styleBtn, prefixIcon, suffixIcon, disabled = false, handleSubmit, isLoading, text, m, w="100%", h = "48px" }) => {
    return (
        <Button
            h={h}
            w={w}
            bg="primary"
            hoverBg="brand600"
            p={{ xs: '0.6rem' }}
            m={{ r: "0.5rem", b: "0.25rem" }}
            textSize={textSize}
            prefix={prefixIcon}
            suffix={suffixIcon}
            onClick={
                handleSubmit
            }
            hoverShadow="2"
            disabled={disabled || isLoading}
            style={{ ...styleBtn }}
        >
            {isLoading ?
                <Icon name="Loading" color="background" size="20px" />
                :
                text ? text : "Confirm"
            }

        </Button>
    )
}

export default PrimaryBtn
