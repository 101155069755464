import Styled from 'styled-components'

export const theme = (color: string[] = ["#F0F0F0", "#F8F8F8"]) => `
display: inline-block;
height: 100%;
width: 100%;
background: linear-gradient(-90deg, ${color[0]} 0%, ${color[1]} 50%, ${color[0]} 100%);
background-size: 400% 400%;
animation: pulse 1.2s ease-in-out infinite;
border-radius: 4px;

@keyframes pulse {
    0% {
    background-position: 0% 0%;
    }
    100% {
    background-position: -135% 0%;
    }
}
`
interface SkeletonProps {
  height?: string
  borderRadius?: string
  margin?: string
  width?: string
  minHeight?: string
  flex?: string
  color: any
  minWidth: any
}

export const Skeleton = Styled.div<SkeletonProps>`
  ${props => theme(props.color)}
  flex: ${props => props.flex};
  height: ${props => props.height ? props.height : "100%"};
  width: ${props => props.width ? props.width : "100%"};
  margin: ${props => props.margin ? props.margin : "0"};
  border-radius: ${props => props.borderRadius ? props.borderRadius : ""};
  min-height: ${props => props.minHeight ? props.minHeight : ""};
  min-width: ${props => props.minWidth ? props.minWidth : ""};


  &.table_skeleton {
    display: block;
  }

  &.fixed_skeleton {
    width: calc(50vw - 60px);
    height: calc(100% + 80px);
    margin: -40px;
  }

`;



