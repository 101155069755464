import { Helmet } from 'react-helmet';
import React, { useContext } from 'react'
import tms from "../../assets/images/tms.png"
import { Text, Div, Icon } from "atomize";
import usePreloadImages from '../../helpers/CustomHooks/usePreloadImages';
import useWindowDimensions from '../../helpers/CustomHooks/useWindowDimensions';
import Lang from '../../helpers/Text/Lang';
import Border from '../../components/Border/Border';
import Subscribe from '../../components/Subscribe/Subscribe';
import { LangVarContext } from '../../App';

const TMS: React.FC<{}> = () => {
    const { langVar } = useContext(LangVarContext)
    usePreloadImages(false)
    const { width } = useWindowDimensions()

    return (
        <>
            <Helmet>
                <title>TMS Tool for Logistics | Cargobite</title>
                <meta content="TMS Tool for Logistics | Cargobite" property="og:title" />
                <meta content="TMS Tool for Logistics | Cargobite" name="twitter:title" />
                <meta content="Dive into how our Transport Management System simplifies logistics, from seamless order management to live fleet tracking, all while saving you time and money." name="description" />
                <meta content="Dive into how our Transport Management System simplifies logistics, from seamless order management to live fleet tracking, all while saving you time and money." property="og:description" />
                <meta content="Dive into how our Transport Management System simplifies logistics, from seamless order management to live fleet tracking, all while saving you time and money." name="twitter:description" />
            </Helmet>
            <Div>
                <Text tag="h3" textSize="tiny" textAlign="left"  textTransform="uppercase" textColor="brand600" textWeight="700" width={width} m={{ t: width > 700 ? "4rem" : "1.5rem" }}>
                    {Lang(langVar).tms.label}
                </Text>
                <Text tag="h1" textAlign="left" textSize={{ xs: "display2", md: "display3" }} textWeight="700" textColor="dark" m={{ t: width > 700 ? "1rem" : "0.25rem" }}>
                    {Lang(langVar).tms.title}
                </Text>
                <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                    {Lang(langVar).tms.subtitle}
                </Text>

                <Subscribe module={Lang('EN').tms.label} />
            </Div>

            <img src={tms} style={{ width: width > 600 ? "calc(100% + 32rem)" : "calc(100% + 14rem)", margin: width > 600 ? "7.75rem -6rem 0" : "3.75rem -7rem 0"}} alt="" />
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).tms.modules_title} </Text>
                
                <Div d="flex" flexDir="row" align="center" w="100%" m={{ t: width > 700 ? "1rem": "0.5rem" }} >
                  <Icon name="Dot" color="brand600" size="20px" m={{ r: "0.25rem" }} />
                  <Text tag="h2" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500">
                    {Lang(langVar).tms.modules_item1}
                  </Text>
                </Div>
                <Div d="flex" flexDir="row" align="center" w="100%" m={{ t: width > 700 ? "1rem": "0.5rem" }} >
                  <Icon name="Dot" color="brand600" size="20px" m={{ r: "0.25rem" }} />
                  <Text tag="h2" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500">
                    {Lang(langVar).tms.modules_item2}
                  </Text>
                </Div>
                <Div d="flex" flexDir="row" align="center" w="100%" m={{ t: width > 700 ? "1rem": "0.5rem" }} >
                  <Icon name="Dot" color="brand600" size="20px" m={{ r: "0.25rem" }} />
                  <Text tag="h2" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500">
                    {Lang(langVar).tms.modules_item3}
                  </Text>
                </Div>

                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>

            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).tms.order_management_title} </Text>
                <Box width={width} title={Lang(langVar).tms.setup_title} text={Lang(langVar).tms.setup_body}/>
                <Box width={width} title={Lang(langVar).tms.tracking_title} text={Lang(langVar).tms.tracking_body}/>
                <Box width={width} title={Lang(langVar).tms.document_handling_title} text={Lang(langVar).tms.document_handling_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>

            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).tms.enhance_title} </Text>
                <Box width={width} title={Lang(langVar).tms.rate_access_title} text={Lang(langVar).tms.rate_access_body}/>
                <Box width={width} title={Lang(langVar).tms.permissions_title} text={Lang(langVar).tms.permissions_body}/>
                <Box width={width} title={Lang(langVar).tms.planning_title} text={Lang(langVar).tms.planning_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).tms.streamline_title} </Text>
                <Box width={width} title={Lang(langVar).tms.automation_title} text={Lang(langVar).tms.automation_body}/>
                <Box width={width} title={Lang(langVar).tms.estimates_title} text={Lang(langVar).tms.estimates_body}/>
                <Box width={width} title={Lang(langVar).tms.bulk_invoicing_title} text={Lang(langVar).tms.bulk_invoicing_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                  <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).tms.innovate_title} </Text>
                <Box width={width} title={Lang(langVar).tms.tracking_implementation_title} text={Lang(langVar).tms.tracking_implementation_body}/>
                <Box width={width} title={Lang(langVar).tms.compliance_automation_title} text={Lang(langVar).tms.compliance_automation_body}/>
                <Box width={width} title={Lang(langVar).tms.insights_title} text={Lang(langVar).tms.insights_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}></Div>
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: width > 700 ? "4rem" : "2rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Text tag="h4" textAlign="left" textSize={{ xs: "display1", md: "display3" }} textWeight="700" textColor="dark" m={{ t: "4rem" }}>
                {Lang(langVar).tms.cta}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).tms.cta_body}
            </Text>

            <Subscribe module={Lang('EN').tms.label} />

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>
        </>
    )
}

export default TMS

const Box: React.FC<{ width: number, title: string, text: string}> = ({ width, title, text }) => {

    return (
        <Div m={{ t: width > 700 ? "4rem" : "2rem" }}>
            <Text tag="h3" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500" m={{ b: "1rem" }}>
                    {title}
            </Text>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
                {text}
            </Text>
        </Div>
    )
}