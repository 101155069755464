import { useEffect, useState } from "react";
import bg_img from "../../assets/images/landing_bg.svg"
import contractor_marketplace from "../../assets/images/contractor_marketplace.png"
import documents_transporters from "../../assets/images/documents_transporters.png"
import fleet_transporters from "../../assets/images/fleet_transporters.png"
import inbound_sales_ai from "../../assets/images/inbound_sales_ai.png"
import order_details_screenshot from "../../assets/images/order_details_screenshot.png"
import order_management_suppliers from "../../assets/images/order_management_suppliers.png"
import order_management_transporters from "../../assets/images/order_management_transporters.png"
import order_recognition from "../../assets/images/order_recognition.png"
import slot_scheduling from "../../assets/images/slot_scheduling.png"
import order_fleet_documents from "../../assets/images/order_fleet_documents.png"
import relay from "../../assets/images/relay.png"
import tms from "../../assets/images/tms.png"
import recognition_inbound from "../../assets/images/recognition_inbound.png"

const unpackArr = [
  bg_img,
  contractor_marketplace,
  documents_transporters,
  fleet_transporters,
  inbound_sales_ai,
  order_details_screenshot,
  order_management_suppliers,
  order_management_transporters,
  order_recognition,
  slot_scheduling,
  order_fleet_documents,
  relay,
  tms,
  recognition_inbound
];

export default function usePreloadImages(
  unpackImages?: boolean,
  imgArr: string[] = []
): { allLoaded: boolean } {

  // this hook is used to preload images
  const [allLoaded, setAllLoaded] = useState(false);

  useEffect(() => {
    const newArr = unpackImages ? [...imgArr, ...unpackArr] : imgArr;

    if (newArr === null) return;
    let c = 0;
    newArr.forEach((img) => {
      const newImg = new Image();
      newImg.onload = () => {
        c += 1;
        if (c === newArr.length) {
          setAllLoaded(true);
        }
      };
      newImg.src = img;
    });
  }, [imgArr, unpackImages]);

  return {
    allLoaded
  };
}
