import { Helmet } from 'react-helmet';
import React, { useContext } from 'react'
import order_management_transporters from "../../../assets/images/order_management_transporters.png"
import order_fleet_documents from "../../../assets/images/order_fleet_documents.png"
import { Text, Div } from "atomize";
import usePreloadImages from '../../../helpers/CustomHooks/usePreloadImages';
import useWindowDimensions from '../../../helpers/CustomHooks/useWindowDimensions';
import Lang from '../../../helpers/Text/Lang';
import Border from '../../../components/Border/Border';
import { useTheme } from 'styled-components';
import Subscribe from '../../../components/Subscribe/Subscribe';
import { LangVarContext } from '../../../App';
import { Link } from 'react-router-dom';

const OrderManagment: React.FC<{}> = () => {
    const { langVar } = useContext(LangVarContext)
    usePreloadImages(false)
    const { width } = useWindowDimensions()
    const theme = useTheme()

    return (
        <>
            <Helmet>
                <title>Order Managment Tool for Transporters in Logistics | Cargobite</title>
                <meta content="Order Managment Tool for Transporters in Logistics | Cargobite" property="og:title" />
                <meta content="Order Managment Tool for Transporters in Logistics | Cargobite" name="twitter:title" />
                <meta content="Discover how our platform streamlines operations, empowers teams, and boosts profitability in the logistics sector." name="description" />
                <meta content="Discover how our platform streamlines operations, empowers teams, and boosts profitability in the logistics sector." property="og:description" />
                <meta content="Discover how our platform streamlines operations, empowers teams, and boosts profitability in the logistics sector." name="twitter:description" />
            </Helmet>
            <Div>
                <Text tag="h3" textSize="tiny" textAlign="left"  textTransform="uppercase" textColor="success" textWeight="700" width={width} m={{ t: width > 700 ? "4rem" : "1.5rem" }}>
                    {Lang(langVar).transporters.managment.label}
                </Text>
                <Text tag="h1" textAlign="left" textSize={{ xs: "display2", md: "display3" }} textWeight="700" textColor="dark" m={{ t: width > 700 ? "1rem" : "0.25rem" }}>
                    {Lang(langVar).transporters.managment.title}
                </Text>
                <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                    {Lang(langVar).transporters.managment.subtitle}
                </Text>

                <Subscribe module={Lang('EN').transporters.managment.label} />
            </Div>

            <img src={order_management_transporters} style={{ width: width > 600 ? "calc(100% + 32rem)" : "calc(100% + 14rem)", margin: width > 600 ? "7.75rem -6rem 0" : "3.75rem -7rem 0"}} alt="Cargobite Order Managment for Transporters image" />
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).transporters.managment.streamline} </Text>
                <Box width={width} title={Lang(langVar).transporters.managment.setup_title} text={Lang(langVar).transporters.managment.setup_body}/>
                <Box width={width} title={Lang(langVar).transporters.managment.realtime_title} text={Lang(langVar).transporters.managment.realtime_body}/>
                <Box width={width} title={Lang(langVar).transporters.managment.simplified_title} text={Lang(langVar).transporters.managment.simplified_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>

            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).transporters.managment.optimize_title} </Text>
                <Box width={width} title={Lang(langVar).transporters.managment.best_title} text={Lang(langVar).transporters.managment.best_body}/>
                <Box width={width} title={Lang(langVar).transporters.managment.custom_title} text={Lang(langVar).transporters.managment.custom_body}/>
                <Box width={width} title={Lang(langVar).transporters.managment.structured_title} text={Lang(langVar).transporters.managment.structured_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).transporters.managment.collaborate} </Text>
                <Box width={width} title={Lang(langVar).transporters.managment.never_title} text={Lang(langVar).transporters.managment.never_body}/>
                <Box width={width} title={Lang(langVar).transporters.managment.seamless_title} text={Lang(langVar).transporters.managment.seamless_body}/>
                <Box width={width} title={Lang(langVar).transporters.managment.direct_title} text={Lang(langVar).transporters.managment.direct_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}></Div>
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: width > 700 ? "4rem" : "2rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <img src={order_fleet_documents} style={{ width: "100%", margin: "4rem 0 1rem" }} alt="Cargobite TMS Bundle image" />

            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="semiDark" textWeight="700"> {Lang(langVar).bundle} </Text>
                <Text tag="h3" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.5rem" }}>
                    {Lang(langVar).transporters.managment.bundle.first}
                    <Link to="/module/document-issuing-for-transporters" style={{ color: theme?.colors?.brand600 }}>
                        {Lang(langVar).transporters.managment.bundle.document}
                    </Link>
                    <span> & </span>
                    <Link to="/module/fleet-tracking-for-transporters" style={{ color: theme?.colors?.brand600 }}>
                        {Lang(langVar).transporters.managment.bundle.fleet}
                    </Link>
                    {Lang(langVar).transporters.managment.bundle.last}
                </Text>
                <Link
                    to="/product/tms"
                    style={{ marginTop: width > 700 ? "1rem" : "0.5rem" }}
                >
                    <Text
                        textSize={{ xs: "body", md: 16 }}
                        textColor="brand600"
                        hoverTextColor="brand700"
                        textWeight="700"
                        style={{ transition: "color 0.3s ease-in-out" }}
                    >
                        {Lang(langVar).transporters.managment.bundle_btn}
                    </Text>
                </Link>
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Text tag="h4" textAlign="left" textSize={{ xs: "display1", md: "display3" }} textWeight="700" textColor="dark" m={{ t: "4rem" }}>
                {Lang(langVar).transporters.managment.cta}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).transporters.managment.cta_body}
            </Text>

            <Subscribe module={Lang('EN').transporters.managment.label} />

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>
        </>
    )
}

export default OrderManagment

const Box: React.FC<{ width: number, title: string, text: string}> = ({ width, title, text }) => {

    return (
        <Div m={{ t: width > 700 ? "4rem" : "2rem" }}>
            <Text tag="h3" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500" m={{ b: "1rem" }}>
                    {title}
            </Text>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
                {text}
            </Text>
        </Div>
    )
}