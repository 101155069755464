const en = {
  label: "contractor marketplace for shippers",
  title: "Optimize your transport logistics with Cargobite's Contractor Marketplace",
  subtitle: "Elevate your transport procurement process with the Contractor Marketplace, designed for shippers to effortlessly find the best transport offers, ensuring both efficiency and reliability in your logistics chain.",
  access_title: "Gain access to competitive transport bids",
  receive_title: "Receive and compare transport offers",
  receive_body: "Access a wide range of bids for your transport needs in one place. Our platform simplifies how you receive offers, enabling you to compare and choose the most suitable option based on price, timing, and transporter reliability.",
  selection_title: "Seamless selection of preferred offers",
  selection_body: "Once you've reviewed the bids, selecting your preferred transporter is just a click away. Add your choice directly to the order, streamlining the process of securing transport for your goods.",
  decisions_title: "Make informed decisions with transparency and data",
  reviews_title: "Access to transporter reviews and ratings",
  reviews_body: "Make decisions based on past performance. Our platform provides access to detailed transporter reviews and ratings, ensuring you can select partners with confidence, based on their track record of reliability and service quality.",
  history_title: "Review order history for full accountability",
  history_body: "Our system offers comprehensive visibility into your order history with each transporter. This feature ensures full accountability, helping you to build a reliable logistics network over time.",
  cta: "Transform your supply chain with Cargobite",
  cta_body: "Join Cargobite today and take advantage of the Contractor Marketplace module, tailor-made for shippers looking to enhance their logistics operations. Experience the ease of finding competitive bids, the confidence of selecting based on transporter reliability, and the benefit of comprehensive order history for unparalleled transparency. With Cargobite, securing the best transport solutions for your goods has never been easier or more reliable."
}
const si = {
  label: "tržnica izvajalcev za pošiljatelje",
  title: "Optimizirajte svojo transportno logistiko z modulom tržnice izvajalcev Cargobite",
  subtitle: "Povišajte svoj postopek nabave transporta s tržnico izvajalcev, zasnovano za pošiljatelje, da brez težav najdejo najboljše ponudbe za transport, zagotavljajoč tako učinkovitost kot zanesljivost v vaši logistični verigi.",
  access_title: "Pridobite dostop do konkurenčnih ponudb za transport",
  receive_title: "Prejmite in primerjajte ponudbe za transport",
  receive_body: "Dostopajte do širokega spektra ponudb za vaše transportne potrebe na enem mestu. Naša platforma poenostavlja način prejemanja ponudb, omogočajoč vam primerjavo in izbiro najbolj primerne možnosti glede na ceno, čas in zanesljivost prevoznika.",
  selection_title: "Brezhibna izbira preferiranih ponudb",
  selection_body: "Ko ste pregledali ponudbe, je izbira preferiranega prevoznika le nekaj kliki stran. Svojo izbiro dodajte neposredno v naročilo, kar poenostavlja proces zagotavljanja prevoza za vaše blago.",
  decisions_title: "Sprejemajte informirane odločitve z transparentnostjo in podatki",
  reviews_title: "Dostop do pregledov in ocen prevoznikov",
  reviews_body: "Sprejemajte odločitve na podlagi preteklih uspehov. Naša platforma omogoča dostop do podrobnih pregledov in ocen prevoznikov, zagotavljajoč, da lahko izberete partnerje s samozavestjo, na podlagi njihovega preteklega zanesljivosti in kakovosti storitev.",
  history_title: "Preglejte zgodovino naročil za popolno odgovornost",
  history_body: "Naš sistem ponuja celovit pregled nad vašo zgodovino naročil pri vsakem prevozniku. Ta funkcija zagotavlja popolno odgovornost, vam pomaga graditi zanesljivo logistično mrežo v času.",
  cta: "Transformirajte svojo dobavno verigo z Cargobitejem",
  cta_body: "Pridružite se Cargobiteju danes in izkoristite modul tržnice izvajalcev, izdelan po meri za pošiljatelje, ki želijo izboljšati svoje logistične operacije. Doživite enostavnost iskanja konkurenčnih ponudb, samozavest izbire na podlagi zanesljivosti prevoznika in koristi celovite zgodovine naročil za brezprimerno transparentnost. Z Cargobitejem še nikoli ni bilo lažje ali zanesljiveje zagotoviti najboljše transportne rešitve za vaše blago."
}

const langContractorMarketplaceShippers = { en, si }

export default langContractorMarketplaceShippers