import { Helmet } from 'react-helmet';
import React, { useContext } from 'react'
import slot_scheduling from "../../../assets/images/slot_scheduling.png"
import { Text, Div } from "atomize";
import usePreloadImages from '../../../helpers/CustomHooks/usePreloadImages';
import useWindowDimensions from '../../../helpers/CustomHooks/useWindowDimensions';
import Lang from '../../../helpers/Text/Lang';
import Border from '../../../components/Border/Border';
import Subscribe from '../../../components/Subscribe/Subscribe';
import { LangVarContext } from '../../../App';

const SlotScheduling: React.FC<{}> = () => {
    const { langVar } = useContext(LangVarContext)
    usePreloadImages(false)
    const { width } = useWindowDimensions()

    return (
        <>
            <Helmet>
                <title>Slot Scheduling Tool for Shippers in Logistics | Cargobite</title>
                <meta content="Slot Scheduling Tool for Shippers in Logistics | Cargobite" property="og:title" />
                <meta content="Slot Scheduling Tool for Shippers in Logistics | Cargobite" name="twitter:title" />
                <meta content="Harness the power of precision scheduling with the Slot Scheduling module, designed for shippers to seamlessly coordinate transport slots for pickups and drop-offs, optimizing logistics operations for enhanced efficiency." name="description" />
                <meta content="Harness the power of precision scheduling with the Slot Scheduling module, designed for shippers to seamlessly coordinate transport slots for pickups and drop-offs, optimizing logistics operations for enhanced efficiency." property="og:description" />
                <meta content="Harness the power of precision scheduling with the Slot Scheduling module, designed for shippers to seamlessly coordinate transport slots for pickups and drop-offs, optimizing logistics operations for enhanced efficiency." name="twitter:description" />
            </Helmet>
            <Div>
                <Text tag="h3" textSize="tiny" textAlign="left"  textTransform="uppercase" textColor="warning" textWeight="700" width={width} m={{ t: width > 700 ? "4rem" : "1.5rem" }}>
                    {Lang(langVar).shippers.scheduling.label}
                </Text>
                <Text tag="h1" textAlign="left" textSize={{ xs: "display2", md: "display3" }} textWeight="700" textColor="dark" m={{ t: width > 700 ? "1rem" : "0.25rem" }}>
                    {Lang(langVar).shippers.scheduling.title}
                </Text>
                <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                    {Lang(langVar).shippers.scheduling.subtitle}
                </Text>

                <Subscribe module={Lang('EN').shippers.scheduling.label} />
            </Div>

            <img src={slot_scheduling} style={{ width: width > 600 ? "calc(100% + 32rem)" : "calc(100% + 14rem)", margin: width > 600 ? "7.75rem -6rem 0" : "3.75rem -7rem 0"}} alt="Cargobite Slot Scheduling for Shippers image" />
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).shippers.scheduling.manage_title} </Text>
                <Box width={width} title={Lang(langVar).shippers.scheduling.customizable_title} text={Lang(langVar).shippers.scheduling.customizable_body}/>
                <Box width={width} title={Lang(langVar).shippers.scheduling.realtime_title} text={Lang(langVar).shippers.scheduling.realtime_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>

            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).shippers.scheduling.coordination_title} </Text>
                <Box width={width} title={Lang(langVar).shippers.scheduling.notifications_title} text={Lang(langVar).shippers.scheduling.notifications_body}/>
                <Box width={width} title={Lang(langVar).shippers.scheduling.visibility_title} text={Lang(langVar).shippers.scheduling.visibility_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).shippers.scheduling.partnerships_title} </Text>
                <Box width={width} title={Lang(langVar).shippers.scheduling.feedback_title} text={Lang(langVar).shippers.scheduling.feedback_body}/>
                <Box width={width} title={Lang(langVar).shippers.scheduling.transparent_title} text={Lang(langVar).shippers.scheduling.transparent_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}></Div>
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: width > 700 ? "4rem" : "2rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Text tag="h4" textAlign="left" textSize={{ xs: "display1", md: "display3" }} textWeight="700" textColor="dark" m={{ t: "4rem" }}>
                {Lang(langVar).shippers.scheduling.cta}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).shippers.scheduling.cta_body}
            </Text>

            <Subscribe module={Lang('EN').shippers.scheduling.label} />

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>
        </>
    )
}

export default SlotScheduling

const Box: React.FC<{ width: number, title: string, text: string}> = ({ width, title, text }) => {

    return (
        <Div m={{ t: width > 700 ? "4rem" : "2rem" }}>
            <Text tag="h3" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500" m={{ b: "1rem" }}>
                    {title}
            </Text>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
                {text}
            </Text>
        </Div>
    )
}