const en = {
  label: "fleet tracking for transporters",
  title: "Simplify and economize fleet tracking with Cargobite",
  subtitle: "Discover how Cargobite makes live fleet tracking accessible and affordable, eliminating the need for devices and cutting costs significantly.",
  accessible_title: "Accessible live tracking for every fleet",
  setup_title: "Effortless setup with no devices required",
  setup_body: "Embrace live tracking with ease. Cargobite’s innovative approach requires no additional hardware, making it simpler and faster to get your fleet connected and visible in real-time.",
  cost_title: "Cost-effective operations at a fraction of the price",
  cost_body: "Transform your fleet management with live tracking that’s approximately 10x cheaper than traditional device-based solutions. With Cargobite, you enjoy substantial savings while gaining invaluable insights into your fleet.",
  coordination_title: "Enhance driver coordination with live chat and comprehensive order management",
  realtime_title: "Streamline communication with real-time chat",
  realtime_body: "Foster a direct line of communication between your dispatch team and drivers. With Cargobite's live chat feature, address queries, provide instant updates, and make critical decisions on the go, ensuring smoother operations and enhanced team coordination.",
  complete_title: "Complete order details at your drivers' fingertips",
  complete_body: "Empower your drivers with the ability to access all order-related information directly from their devices. From pickup and delivery instructions to special handling notes, everything is available in real-time, enabling drivers to manage their tasks more efficiently and reduce the likelihood of errors.",
  efficient_title: "Efficient document handling and uploads",
  efficient_body: "Simplify the paperwork process with our intuitive platform. Drivers can easily view, upload, and manage necessary order documents on the move, ensuring compliance and streamlining the documentation process. This not only saves time but also reduces the administrative burden on your team.",
  compliance_title: "Ensure seamless compliance with smart document management",
  streamlined_title: "Streamlined document compliance made easy",
  streamlined_body: "Achieve and maintain compliance effortlessly. Our platform automates the management of all necessary documents, ensuring your fleet adheres to regulations without the manual hassle.",
  alerts_title: "Proactive alerts for document renewals",
  alerts_body: "Never miss a renewal with automated reminders. Keep your fleet’s documentation up to date, avoiding penalties and ensuring continuous, compliant operation.",
  leverage_title: "Leverage real-time data for operational efficiency",
  insights_title: "Real-time insights for smarter fleet management",
  insights_body: "Use live tracking data to optimize routes, decrease idle times, and reduce fuel consumption. Make informed decisions that boost efficiency and productivity.",
  schedule_title: "Simplified driver schedule and leave management",
  schedule_body: "Directly manage driver availability through the Driver's App. Effortlessly handle time-off requests and vacation planning to maintain optimal fleet operations.",
  bundle: {
    first: "Use fleet tracking module as standalone application or bundle it together with ",
    last: " modules in our TMS product.",
    document: "order management",
    fleet: "document issuing"
  },
  bundle_btn: "View TMS product →",
  cta: "Drive forward with Cargobite’s cost-effective fleet tracking",
  cta_body: "With Cargobite, step into a new era of fleet management where advanced live tracking and document compliance are not only easy to implement but also incredibly affordable. Say goodbye to expensive hardware and embrace a solution that offers both visibility and value. Join Cargobite today and experience the simplicity and savings of managing your fleet in the modern age."
}
const si = {
  label: "sledenje vozil za prevoznike",
  title: "Poenostavite in ekonomizirajte sledenje vozil z Cargobite",
  subtitle: "Odkrijte, kako Cargobite omogoča dostopno in cenovno ugodno sledenje v realnem času, odpravlja potrebo po napravah in znatno zmanjšuje stroške.",
  accessible_title: "Dostopno sledenje v realnem času za vsak vozni park",
  setup_title: "Brez naprav, brez truda pri nastavitvi",
  setup_body: "Enostavno sprejmite sledenje v realnem času. Inovativni pristop Cargobite ne zahteva dodatne strojne opreme, kar olajša in pospeši povezavo vašega vozila in njegovo vidnost v realnem času.",
  cost_title: "Stroškovno učinkovito delovanje za delček cene",
  cost_body: "Preoblikujte upravljanje vozil s sledenjem v realnem času, ki je približno 10-krat cenejše od tradicionalnih rešitev na osnovi naprav. Z Cargobite prihranite znatno, hkrati pa pridobite neprecenljive vpoglede v svoj vozni park.",
  coordination_title: "Izboljšajte koordinacijo voznikov z živo klepetom in celovitim upravljanjem naročil",
  realtime_title: "Optimizacija komunikacije s klepetom v realnem času",
  realtime_body: "Spodbujajte neposredno komunikacijsko linijo med vašo ekipo za odpremo in vozniki. S funkcijo klepeta v živo Cargobite lahko rešujete poizvedbe, zagotavljate takojšnje posodobitve in sprejemate kritične odločitve med vožnjo, kar zagotavlja bolj gladko delovanje in izboljšano koordinacijo ekipe.",
  complete_title: "Celovite podrobnosti naročil na dosegu voznikovih prstov",
  complete_body: "Omočite svoje voznike z možnostjo dostopa do vseh informacij, povezanih z naročilom, neposredno prek svojih naprav. Od navodil za prevzem in dostavo do posebnih opomb o ravnanju, vse je na voljo v realnem času, kar voznikom omogoča učinkovitejše upravljanje nalog in zmanjšuje verjetnost napak.",
  efficient_title: "Učinkovito upravljanje dokumentov in nalaganje",
  efficient_body: "Poenostavite postopek dokumentacije z našo intuitivno platformo. Vozniki lahko enostavno pregledujejo, nalagajo in upravljajo potrebne dokumente naročil med vožnjo, kar zagotavlja skladnost in poenostavlja postopek dokumentacije. To ne le prihrani čas, ampak tudi zmanjšuje administrativno breme vaše ekipe.",
  compliance_title: "Zagotovite brezhibno skladnost z pametnim upravljanjem dokumentov",
  streamlined_title: "Poenostavljena skladnost z dokumentacijo na enem mestu",
  streamlined_body: "Brez napora dosežite in ohranjajte skladnost. Naša platforma avtomatizira upravljanje vseh potrebnih dokumentov, zagotavljajoč, da vaš vozni park spoštuje predpise brez ročnih težav.",
  alerts_title: "Proaktivna opozorila za obnove dokumentov",
  alerts_body: "Nikoli več ne zamudite obnove s samodejnimi opomniki. Obdržite dokumentacijo vašega vozila posodobljeno, se izognite kaznim in zagotovite neprekinjeno, skladno delovanje.",
  leverage_title: "Izkoristite podatke v realnem času za operativno učinkovitost",
  insights_title: "Podatkovni vpogledi v realnem času za pametno upravljanje voznega parka",
  insights_body: "Uporabite podatke o sledenju v realnem času za optimizacijo poti, zmanjšanje časa mirovanja in zmanjšanje porabe goriva. Sprejemajte informirane odločitve, ki povečujejo učinkovitost in produktivnost.",
  schedule_title: "Poenostavljeno upravljanje urnikov voznikov in dopustov",
  schedule_body: "Neposredno upravljajte razpoložljivost voznikov prek aplikacije za voznike. Brez težav obravnavajte zahteve za prosti čas in načrtujte dopuste, da ohranite optimalno delovanje vašega vozila.",
  bundle: {
    first: "Uporabite modul za sledenje vozil kot samostojno aplikacijo ali ga združite s moduli za ",
    last: " v našem izdelku TMS.",
    document: "upravljanje naročil",
    fleet: "izdajanje dokumentov"
  },
  bundle_btn: "Ogled izdelka TMS →",
  cta: "Napredujte s stroškovno učinkovitim sledenjem vozil Cargobite",
  cta_body: "Z Cargobite stopite v novo dobo upravljanja voznega parka, kjer je napredno sledenje v realnem času in skladnost z dokumenti ne le enostavna za izvajanje, temveč tudi izjemno cenovno ugodna. Poslovite se od drage strojne opreme in sprejmite rešitev, ki ponuja tako vidnost kot vrednost. Pridružite se Cargobite danes in doživite preprostost in prihranke pri upravljanju vašega voznega parka v moderni dobi."
}

const langFleetTracking = { en, si }

export default langFleetTracking