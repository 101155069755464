
import { BrowserRouter, Redirect, Route, Switch, useParams } from "react-router-dom"
import * as Styled from "./App.styles"
import React, { createContext, useEffect, useState } from 'react'
import Landing from './pages/Landing/Landing'
import * as Sentry from "@sentry/react"
import ScrollToTop from './helpers/ScrollToTop'
import useWindowDimensions from './helpers/CustomHooks/useWindowDimensions'
import { Div } from "atomize"
import Header from './layout/Header/Header'
import Cnt from './layout/Cnt/Cnt'
import Page404 from './pages/Page404/Page404'
import { config } from "./config"
import jwt_decode from "jwt-decode"
import { LogSnagProvider } from "@logsnag/react"
import OrderManagmentTransporters from "./pages/Module/Transporters/OrderManagment"
import DocumentIssuingTransporters from "./pages/Module/Transporters/DocumentIssuing"
import FleetTrackingTransporters from "./pages/Module/Transporters/FleetTracking"
import ContractorMarketplaceTransporters from "./pages/Module/Transporters/ContractorMarketplace"
import SlotSchedulingTransporters from "./pages/Module/Transporters/SlotScheduling"
import OrderRecognitionTransporters from "./pages/Module/Transporters/OrderRecognition"
import InboundSalesAITransporters from "./pages/Module/Transporters/InboundSalesAI"
import OrderManagmentShippers from "./pages/Module/Shippers/OrderManagment"
import ContractorMarketplaceShippers from "./pages/Module/Shippers/ContractorMarketplace"
import SlotSchedulingShippers from "./pages/Module/Shippers/SlotScheduling"
import TMS from "./pages/Product/TMS"
import Relay from "./pages/Product/Relay"
import Footer from "./layout/Footer/Footer"
var createGuest = require("cross-domain-storage/guest")

export const LangVarContext = createContext<{
  langVar: 'EN' | 'SI',
  setLangVar: (langVar: 'EN' | 'SI') => void}>
({
  langVar: 'EN',
  setLangVar: () => {},
})

const App: React.FC = () => {
  const [token, setToken] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [langVar, setLangVar] = useState<'EN' | 'SI'>('EN')

  const cg = async () => {
    var bazStorage = createGuest(config.app_site_url)
    await bazStorage.get("token", (error: any, value: string) => {
      // value for the key of 'fizz' will be retrieved from localStorage on www.baz.com
      if (value) {
        const j: any = jwt_decode(value)
        if (Date.now() >= (j?.exp * 1000)) {
          setToken("")
        } else {
          setToken(value)
        }
      }
      if (error) {
        setToken("")
      }
    })
  }

  useEffect(() => {
    cg()
    const storedLang = localStorage.getItem("langVar") as "EN" | "SI" || "EN"
    if (storedLang != langVar) {
      setLangVar(storedLang)
    }
    setInterval(() => {
      setIsLoading(false)
    }, 900)
  }, [])

  useEffect(() => {
    localStorage.setItem("langVar", langVar)
  }, [langVar])


  const { width } = useWindowDimensions()

  return (
    <Styled.App  >
      <LangVarContext.Provider value={{ langVar, setLangVar}}>
        <LogSnagProvider
          token="ac2965564be59648d9f03628f6c0d2b4"
          project="cargobite"
        >
          <BrowserRouter>
            <ScrollToTop />
            <Switch>
              <Route path={["/", "/module/:moduleName", "/product/:productName"]} exact>
                <Div maxW={`${width}px`}>
                  <Div overflow="hidden">
                    <Header token={token} isLoading={isLoading} />
                    <Cnt maxW={"800px"} flexDir={"column"}>
                      <DynamicRouter />
                    </Cnt>
                    <Footer />
                  </Div>
                </Div>
              </Route>
              <Route path="/">
                <Page404 />
              </Route>
            </Switch >
          </BrowserRouter >
        </LogSnagProvider>
      </LangVarContext.Provider>
    </Styled.App >
  )
}

const DynamicRouter: React.FC<any> = () => {
  const { moduleName, productName } = useParams<{ moduleName?: string, productName?: string }>()
  switch (true) {
    case !!moduleName:
      return <ModuleRouter moduleName={moduleName} />;
    case !!productName:
      return <ProductRouter productName={productName} />;
    default:
      return <Landing />;
  }
}

const ModuleRouter: React.FC<{ moduleName?: string }> = ({ moduleName }) => {
  switch (moduleName) {
    case 'order-management-for-transporters':
      return <OrderManagmentTransporters />
    case 'document-issuing-for-transporters':
      return <DocumentIssuingTransporters />
    case 'fleet-tracking-for-transporters':
      return <FleetTrackingTransporters />
    case 'contractor-marketplace-for-transporters':
      return <ContractorMarketplaceTransporters />
    case 'slot-scheduling-for-transporters':
      return <SlotSchedulingTransporters />
    case 'order-recognition-for-transporters':
      return <OrderRecognitionTransporters />
    case 'inbound-sales-ai-for-transporters':
      return <InboundSalesAITransporters />
    case 'order-management-for-shippers':
      return <OrderManagmentShippers />
    case 'contractor-marketplace-for-shippers':
      return <ContractorMarketplaceShippers />
    case 'slot-scheduling-for-shippers':
      return <SlotSchedulingShippers />
    default:
      return <Redirect to="/" />
  }
}

const ProductRouter: React.FC<{ productName?: string }> = ({ productName }) => {
  switch (productName) {
    case 'tms':
      return <TMS />
    case 'relay':
      return <Relay />
    default:
      return <Redirect to="/" />
  }
}

export default Sentry.withProfiler(App)
