const routeConfig = {
  'order-management-for-transporters': {
      url: '/module/order-management-for-transporters',
      icon: 'HomeSolid'
  },
  'document-issuing-for-transporters': {
      url: '/module/document-issuing-for-transporters',
      icon: 'FolderSolid'
  },
  'fleet-tracking-for-transporters': {
      url: '/module/fleet-tracking-for-transporters',
      icon: 'LocationSolid'
  },
  'contractor-marketplace-for-transporters': {
      url: '/module/contractor-marketplace-for-transporters',
      icon: 'StarSolid'
  },
  'order-recognition-for-transporters': {
      url: '/module/order-recognition-for-transporters',
      icon: 'CameraSolid'
  },
  'slot-scheduling-for-transporters': {
      url: '/module/slot-scheduling-for-transporters',
      icon: 'TimestampSolid'
  },
  'inbound-sales-ai-for-transporters': {
      url: '/module/inbound-sales-ai-for-transporters',
      icon: 'Success'
  },
  'order-management-for-shippers': {
      url: '/module/order-management-for-shippers',
      icon: 'HomeSolid'
  },
  'contractor-marketplace-for-shippers': {
      url: '/module/contractor-marketplace-for-shippers',
      icon: 'StarSolid'
  },
    'slot-scheduling-for-shippers': {
        url: '/module/slot-scheduling-for-shippers',
        icon: 'TimestampSolid'
    },
    'tms': {
        url: '/product/tms',
    },
    'relay': {
        url: '/product/relay',
    },
}

module.exports = routeConfig;