import React from 'react'
import { Container } from "atomize";
import useWindowDimensions from '../../helpers/CustomHooks/useWindowDimensions';
import { CSSProperties } from 'styled-components';


interface Props {
    children: any
    maxW?: string
    align?: string
    d?: string
    style?: CSSProperties
    flexDir: string
}

const Cnt: React.FC<Props> = ({ children, maxW, align = "center", d = "flex", style, flexDir }) => {
    const { height } = useWindowDimensions()

    return (
        <Container maxW={maxW} align={align} minH={`${height - 57}px`} p="1rem" d={d} style={{ ...style }} flexDir={flexDir}>
            {
                children
            }
        </Container >
    )
}

export default Cnt
