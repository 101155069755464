const en = {
  label: "slot scheduling for shippers",
  title: "Elevate Your Logistics Efficiency with Cargobite's Slot Scheduling",
  subtitle: "Harness the power of precision scheduling with the Slot Scheduling module, designed for shippers to seamlessly coordinate transport slots for pickups and drop-offs, optimizing logistics operations for enhanced efficiency.",
  manage_title: "Efficiently manage your transport schedules",
  customizable_title: "Customizable transport slots",
  customizable_body: "Create and manage slots for transport pickups and drop-offs according to your specific needs. Our platform offers flexibility to set up time windows that align with your operational capacity, ensuring smooth logistics flow.",
  realtime_title: "Real-time scheduling for transporters",
  realtime_body: "Allow transporters to book their transport slots in real-time. This feature ensures that you can efficiently allocate resources, avoiding bottlenecks and maximizing the productivity of your logistics operations.",
  coordination_title: "Enhance coordination and reduce downtime",
  notifications_title: "Automated notifications and reminders",
  notifications_body: "Keep transporters informed with automated notifications and reminders about their scheduled slots. This system reduces missed or late pickups and drop-offs, ensuring a reliable logistics process.",
  visibility_title: "Visibility and control over logistics planning",
  visibility_body: "Gain comprehensive visibility and control over your transport scheduling. Monitor slot bookings, manage changes, and adjust schedules as needed, all from one intuitive platform.",
  partnerships_title: "Build stronger partnerships with transporters",
  feedback_title: "Feedback loop for continuous improvement",
  feedback_body: "Incorporate transporter feedback to continuously refine and improve the slot scheduling process. This collaborative approach fosters stronger partnerships and enhances overall logistics efficiency.",
  transparent_title: "Transparent scheduling enhances reliability",
  transparent_body: "The clear and accessible scheduling system builds trust with your transport partners. Transparent and efficient slot management leads to improved service reliability and partner satisfaction.",
  cta: "Revolutionize your supply chain management with Cargobite",
  cta_body: "Join Cargobite today and leverage the Slot Scheduling module to elevate your logistics operations. Experience the benefits of precise scheduling, reduced downtime, and enhanced coordination between shippers and transporters. With Cargobite, managing your logistics slots becomes effortless, allowing you to focus on what you do best – running your business."
}
const si = {
  label: "časovno načrtovanje reže za pošiljatelje",
  title: "Povišajte učinkovitost svoje logistike z režimskim načrtovanjem Cargobite",
  subtitle: "Izkoristite moč natančnega načrtovanja s modulom za režimsko načrtovanje, zasnovanim za pošiljatelje, da brezhibno usklajujejo prevozne reže za prevzem in dostavo, optimizirajoč logistične operacije za izboljšano učinkovitost.",
  manage_title: "Učinkovito upravljanje vaših načrtov prevoza",
  customizable_title: "Prilagodljive prevozne reže",
  customizable_body: "Ustvarjajte in upravljajte reže za prevzem in dostavo pošiljk glede na vaše specifične potrebe. Naša platforma nudi prilagodljivost pri določanju časovnih oken, ki se ujemajo z vašo operativno zmogljivostjo, kar zagotavlja tekoč pretok logistike.",
  realtime_title: "Rezervacije v realnem času za prevoznike",
  realtime_body: "Dovolite prevoznikom, da rezervirajo svoje prevozne reže v realnem času. Ta funkcija zagotavlja, da lahko učinkovito razporedite vire, se izognete zastojem in povečate produktivnost vaših logističnih operacij.",
  coordination_title: "Povečajte usklajenost in zmanjšajte čas mirovanja",
  notifications_title: "Avtomatizirana obvestila in opomniki",
  notifications_body: "Obveščajte prevoznike z avtomatiziranimi obvestili in opomniki o njihovih načrtovanih prevoznih režah. Ta sistem zmanjšuje zamujene ali pozne prevzeme in dostave, zagotavlja zanesljiv logistični proces.",
  visibility_title: "Vidnost in nadzor nad načrtovanjem logistike",
  visibility_body: "Pridobite celovito vidnost in nadzor nad vašim časovnim načrtovanjem prevoza. Spremljajte rezervacije prevoznih rež, upravljajte spremembe in prilagajajte urnike po potrebi, vse z ene intuitivne platforme.",
  partnerships_title: "Gradite močnejše partnerstvo s prevozniki",
  feedback_title: "Zanka povratnih informacij za stalno izboljševanje",
  feedback_body: "Vključite povratne informacije prevoznikov, da nenehno izboljšate postopek načrtovanja prevoza. Ta sodelovalni pristop krepi močnejša partnerstva in izboljšuje celotno logistično učinkovitost.",
  transparent_title: "Transparentno načrtovanje povečuje zanesljivost",
  transparent_body: "Jasen in dostopen sistem načrtovanja krepi zaupanje s prevoznimi partnerji. Transparentno in učinkovito upravljanje prevoznih rež vodi v izboljšano zanesljivost storitve in zadovoljstvo partnerjev.",
  cta: "Revolucionirajte upravljanje vaše dobavne verige z Cargobitejem",
  cta_body: "Pridružite se Cargobiteju danes in izkoristite modul za režimsko načrtovanje za izboljšanje vaših logističnih operacij. Doživite prednosti natančnega načrtovanja, zmanjšanja časa mirovanja in izboljšane usklajenosti med pošiljatelji in prevozniki. Z Cargobitejem postane upravljanje vaših logističnih rež enostavno, kar vam omogoča, da se osredotočite na to, kar počnete najbolje - upravljanje vašega podjetja."
}

const langSlotSchedulingShippers = { en, si }

export default langSlotSchedulingShippers