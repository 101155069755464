const en = {
  label: "order management for shippers",
  title: "Elevate your supply operations with Cargobite",
  subtitle: "Discover how our platform enhances operational transparency, simplifies logistics, and maximizes cost efficiency for shippers in the logistics sector.",
  enhance_title: "Enhance transparency and streamline your supply chain",
  tracking_title: "Seamless order tracking",
  tracking_body: "Gain real-time insights into your shipments. Our platform ensures you're always updated, fostering trust and reliability with your partners.",
  management_title: "Efficient order management",
  management_body: "Simplify how you manage logistics with our easy-to-use interface. From creating to tracking orders, our system is designed to streamline your operations.",
  document_title: "Document management made easy",
  document_body: "Access all essential shipping documents in one secure location. Our platform ensures you never lose track of important paperwork, from contracts to delivery proofs.",
  optimize_title: "Optimize costs and control your logistics",
  rates_title: "Access competitive transport rates",
  rates_body: "Reduce your logistics expenses with our comprehensive marketplace. Get instant quotes and choose the best transport options for your needs, ensuring maximum profitability.",
  access_title: "Tailored access for your team",
  access_body: "Empower your team with customized roles and permissions. Our platform provides the flexibility to manage your operations efficiently, from procurement to logistics coordinators.",
  planning_title: "Advanced order planning",
  planning_body: "Strategically manage your shipments with our advanced planning tools. Organize and schedule your logistics operations to meet demand without the guesswork.",
  collaborate_title: "Collaborate effectively and ensure compliance",
  reminders_title: "Automated reminders for critical tasks",
  reminders_body: "Stay ahead with our smart reminders. Whether it's upcoming shipments or document submissions, our platform keeps you on track and compliant.",
  communication_title: "Effortless communication with transporters",
  communication_body: "Streamline your interactions with transport providers. Share updates and negotiate rates directly on our platform, ensuring clear and effective communication.",
  documentation_title: "Streamlined documentation from transporters",
  documentation_body: "Receive and manage transporter documents effortlessly. Our system simplifies the collection and verification of compliance paperwork, making your job easier.",
  cta: "Transform your supply operations",
  cta_body: "Join Cargobite today and embrace the future of logistics management for shippers. With our comprehensive order management tool, optimizing your supply chain, reducing costs, and improving collaboration has never been easier. Advance your business with Cargobite — where efficiency meets innovation."
}
const si = {
  label: "upravljanje naročil za pošiljatelje",
  title: "Povišajte svoje dobavne operacije z Cargobitejem",
  subtitle: "Odkrijte, kako naša platforma izboljšuje operativno preglednost, poenostavlja logistiko in maksimizira stroškovno učinkovitost za pošiljatelje v logističnem sektorju.",
  enhance_title: "Izboljšajte preglednost in poenostavite svojo dobavno verigo",
  tracking_title: "Brezhibno sledenje naročilom",
  tracking_body: "Pridobite realnočasovne vpoglede v vaše pošiljke. Naša platforma zagotavlja, da ste vedno posodobljeni, krepi zaupanje in zanesljivost s partnerji.",
  management_title: "Učinkovito upravljanje naročil",
  management_body: "Poenostavite upravljanje logistike z našim enostavnim vmesnikom. Od ustvarjanja do sledenja naročilom je naš sistem zasnovan tako, da poenostavi vaše operacije.",
  document_title: "Enostavno upravljanje dokumentov",
  document_body: "Dostopajte do vseh bistvenih dokumentov o pošiljanju na enem varnem mestu. Naša platforma zagotavlja, da ne izgubite pomembnih dokumentov, od pogodb do dokazov o dostavi.",
  optimize_title: "Optimizirajte stroške in nadzorujte svojo logistiko",
  rates_title: "Dostop do konkurenčnih tarif za transport",
  rates_body: "Zmanjšajte svoje logistične stroške z našim obsežnim tržnim prostorom. Pridobite takojšnje ponudbe in izberite najboljše transportne možnosti za svoje potrebe, zagotavljajoč maksimalno donosnost.",
  access_title: "Prilagojen dostop za vašo ekipo",
  access_body: "Okrepite svojo ekipo z prilagojenimi vlogami in dovoljenji. Naša platforma zagotavlja prilagodljivost za učinkovito upravljanje vaših operacij, od nabave do logističnih koordinatorjev.",
  planning_title: "Napredno načrtovanje naročil",
  planning_body: "Strateško upravljajte svoje pošiljke z našimi naprednimi orodji za načrtovanje. Organizirajte in načrtujte svoje logistične operacije, da izpolnite povpraševanje brez ugibanja.",
  collaborate_title: "Učinkovito sodelujte in zagotovite skladnost",
  reminders_title: "Avtomatizirani opomniki za kritične naloge",
  reminders_body: "Ostanite korak pred ostalimi z našimi pametnimi opomniki. Ali gre za prihajajoče pošiljke ali oddajo dokumentov, naša platforma vas ohranja na pravi poti in skladno s predpisi.",
  communication_title: "Breznaporna komunikacija s prevozniki",
  communication_body: "Poenostavite svoje interakcije s ponudniki prevoza. Delite posodobitve in pogajajte se o tarifah neposredno na naši platformi, zagotavljajoč jasno in učinkovito komunikacijo.",
  documentation_title: "Poenostavljena dokumentacija od prevoznikov",
  documentation_body: "Prejmite in upravljajte dokumente prevoznikov brez težav. Naš sistem poenostavlja zbiranje in preverjanje dokumentacije o skladnosti, kar olajša vaše delo.",
  cta: "Transformirajte svoje dobavne operacije",
  cta_body: "Pridružite se Cargobiteju danes in sprejmite prihodnost upravljanja logistike za pošiljatelje. Z našim celovitim orodjem za upravljanje naročil je optimizacija vaše dobavne verige, zmanjševanje stroškov in izboljšanje sodelovanja še nikoli ni bilo lažje. Napredujte s Cargobitejem — kjer se učinkovitost srečuje z inovacijami."
}

const langOrderManagmentShippers = { en, si }

export default langOrderManagmentShippers