const en = {
  label: "order recognition for transporters",
  title: "Order Setup, Redefined by AI",
  subtitle: "Say goodbye to manual order creation. Our AI makes setting up orders so simple you will never have to fill in any inputs.",
  effortless_title: "Effortless order creation from every PDF or text conversation",
  capture_title: "Instantly capture order details with AI",
  capture_body: "Transform the way you process orders. Our AI reads your communications, instantly generating accurate orders in Cargobite. No manual input required, just seamless automation.",
  flexible_title: "Flexible order input for quick setup",
  flexible_body: "Whether it’s a document upload or a simple text paste, our system creates detailed orders ready for action. Streamline your workflow with AI that adapts to your needs.",
  connectivity_title: "Universal TMS connectivity",
  seamless_title: "Seamless flow to any TMS",
  seamless_body: "Extend AI's reach beyond Cargobite. Automatically send detailed orders to the TMS you use, ensuring smooth operations across your logistic networks.",
  empowerment_title: "Dispatcher empowerment",
  empowerment_body: "With AI handling order entries, dispatchers can focus on what they do best: optimizing routes and managing the fleet. Our solution integrates perfectly, making transitions and updates effortless.",
  precision_title: "Precision and integration in order management",
  minimize_title: "Minimize errors, maximize efficiency",
  minimize_body: "Let AI eliminate manual mistakes, enhancing your operational accuracy and team’s productivity. Focus on strategic tasks with our smart, automated system.",
  dynamic_title: "Dynamic and connected",
  dynamic_body: "Stay ahead with real-time order adjustments. Cargobite keeps your orders up-to-date, ensuring responsiveness and high-quality service.",
  bundle: {
    first: "Use Order Recognition module as standalone application or bundle it together with ",
    last: " & other integrations modules in our Relay product.",
    recognition: "Order Recognition",
    sales: "Inbound Sales AI"
  },
  bundle_btn: "View Relay product →",
  cta: "Transform with Cargobite’s AI",
  cta_body: "Lead the logistics revolution with Cargobite, where AI reimagines order processing. Dive into a world where orders are set up automatically, integrated seamlessly, and managed more efficiently than ever. Explore the unmatched potential of AI with Cargobite and redefine your logistics management."
}
const si = {
  label: "prepoznavanje naročil za prevoznike",
  title: "Nastavitev Naročil, Ponovno Opredeljena z AI",
  subtitle: "Pozdravite ročno ustvarjanje naročil. Naša AI naredi nastavitev naročil tako preprosto, da nikoli več ne boste morali izpolnjevati nobenih vhodov.",
  effortless_title: "Breznaporno ustvarjanje naročil iz vsakega PDF-a ali pogovora v besedilu",
  capture_title: "Takojšnje zajemanje podrobnosti naročila z AI",
  capture_body: "Preoblikujte način obdelave naročil. Naša AI prebere vaše komunikacije in takoj ustvari natančna naročila v Cargobite. Ni potrebno ročno vnosa, samo brezhibno avtomatizacijo.",
  flexible_title: "Prilagodljiv vnos naročil za hitro nastavitev",
  flexible_body: "Ali gre za nalaganje dokumenta ali preprosto lepljenje besedila, naš sistem ustvari podrobna naročila, pripravljena za ukrepanje. S pomočjo AI, ki se prilagaja vašim potrebam, optimizirajte svoj delovni tok.",
  connectivity_title: "Univerzalna povezljivost s TMS",
  seamless_title: "Brezhiben pretok v kateri koli TMS",
  seamless_body: "Razširite dosežke AI-ja zunaj Cargobite-a. Podrobna naročila samodejno pošljite v TMS, ki ga uporabljate, zagotavljajoč nemoteno delovanje vaših logističnih omrežij.",
  empowerment_title: "Pooblastilo dispečerjev",
  empowerment_body: "Z AI-jem, ki obvladuje vnose naročil, se dispečerji lahko osredotočijo na to, kar delajo najbolje: optimiziranje poti in upravljanje flote. Naša rešitev se popolnoma integrira, kar omogoča brezhibne prehode in posodobitve.",
  precision_title: "Natančnost in integracija v upravljanju naročil",
  minimize_title: "Zmanjšajte napake, povečajte učinkovitost",
  minimize_body: "Dovolite, da AI odpravi ročne napake, izboljšuje operativno natančnost in produktivnost vaše ekipe. Osredotočite se na strateške naloge z našim pametnim, avtomatiziranim sistemom.",
  dynamic_title: "Dinamičen in povezan",
  dynamic_body: "Ostanite korak pred konkurenco s prilagajanjem naročil v realnem času. Cargobite vaša naročila ohranja posodobljena, zagotavljajoč odzivnost in visoko kakovostno storitev.",
  bundle: {
    first: "Uporabite modul za Prepoznavanjem Naročil kot samostojno aplikacijo ali ga združite z ",
    last: " & drugimi moduli integracij v našem izdelku Relay.",
    recognition: "Prepoznavanjem Naročil",
    sales: "Vhodno Prodajo AI"
  },
  bundle_btn: "Ogled izdelka Relay →",
  cta: "Preoblikujte se s Cargobitejevim AI-jem",
  cta_body: "Povedite logistično revolucijo s Cargobite, kjer AI preoblikuje obdelavo naročil. Potopite se v svet, kjer se naročila samodejno nastavijo, integrirajo brezhibno in upravljajo bolj učinkovito kot kdajkoli prej. Raziščite nepremagljiv potencial AI-ja s Cargobite in ponovno opredelite svoje upravljanje logistike."
}

const langOrderRecognition = { en, si }

export default langOrderRecognition