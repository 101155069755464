import { Helmet } from 'react-helmet';
import React, { useContext, useEffect, useState } from 'react'
import order_details_screenshot from "../../assets/images/order_details_screenshot.png"
import pricing from "../../assets/images/pricing.png"
import { Text, Div } from "atomize";
import usePreloadImages from '../../helpers/CustomHooks/usePreloadImages';
import useWindowDimensions from '../../helpers/CustomHooks/useWindowDimensions';
import Lang from '../../helpers/Text/Lang';
import Border from '../../components/Border/Border';
import Subscribe from '../../components/Subscribe/Subscribe';
import { LangVarContext } from '../../App';

const Landing: React.FC<{}> = () => {
    const { langVar } = useContext(LangVarContext)

    usePreloadImages(false)
    const { width } = useWindowDimensions()

    return (
        <>
            <Helmet>
                <title>Cargobite | Collaborative Logistics Tools for Shippers & Transporters</title>
                <meta content="Cargobite | Collaborative Logistics Tools for Shippers & Transporters" property="og:title" />
                <meta content="Cargobite | Collaborative Logistics Tools for Shippers & Transporters" name="twitter:title" />
                <meta content="Discover how our cutting-edge features can help you streamline your processes, reduce costs, and boost efficiency across your entire supply chain." name="description" />
                <meta content="Discover how our cutting-edge features can help you streamline your processes, reduce costs, and boost efficiency across your entire supply chain." property="og:description" />
                <meta content="Discover how our cutting-edge features can help you streamline your processes, reduce costs, and boost efficiency across your entire supply chain." name="twitter:description" />
            </Helmet>
            <Text tag="h1" textAlign="left" textSize={{ xs: "display2", md: "display3" }} textWeight="700" textColor="dark" m={{ t: width > 700 ? "4rem" : "1.5rem" }}>
                {Lang(langVar).landing.title}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).landing.subtitle}
            </Text>

            <Subscribe module={Lang('EN').landing.label} />

            <img src={order_details_screenshot} style={{ width: width > 600 ? "calc(100% + 32rem)" : "calc(100% + 14rem)", margin: width > 600 ? "4rem -6rem 0" : "2rem -7rem 0"}} alt="Cargobite Landing image" />
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="success700" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).landing.upgrade.label} </Text>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.25rem" }}> {Lang(langVar).landing.upgrade.title} </Text>
            </Div>

            <Box width={width} title={Lang(langVar).landing.upgrade.sections_title.visibility} text={Lang(langVar).landing.upgrade.sections_text.visibility}/>
            <Box width={width} title={Lang(langVar).landing.upgrade.sections_title.communication} text={Lang(langVar).landing.upgrade.sections_text.communication}/>
            <Box width={width} title={Lang(langVar).landing.upgrade.sections_title.orders} text={Lang(langVar).landing.upgrade.sections_text.orders}/>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="warning700" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).landing.boost.label} </Text>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.25rem" }}> {Lang(langVar).landing.boost.title} </Text>
            </Div>

            <Box width={width} title={Lang(langVar).landing.boost.sections_title.quotes} text={Lang(langVar).landing.boost.sections_text.quotes}/>
            <Box width={width} title={Lang(langVar).landing.boost.sections_title.partners} text={Lang(langVar).landing.boost.sections_text.partners}/>
            <Box width={width} title={Lang(langVar).landing.boost.sections_title.marketplace} text={Lang(langVar).landing.boost.sections_text.marketplace}/>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="info700" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).landing.scale.label} </Text>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.25rem" }}> {Lang(langVar).landing.scale.title} </Text>
            </Div>

            <Box width={width} title={Lang(langVar).landing.scale.sections_title.hub} text={Lang(langVar).landing.scale.sections_text.hub}/>
            <Box width={width} title={Lang(langVar).landing.scale.sections_title.data} text={Lang(langVar).landing.scale.sections_text.data}/>
            <Box width={width} title={Lang(langVar).landing.scale.sections_title.customization} text={Lang(langVar).landing.scale.sections_text.customization}/>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "3rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <img src={pricing} style={{ width: "100%", margin: "4rem 0 2rem"}} alt="Cargobite Pricing image" />

            <Div d="flex" flexDir="column" align="flex-start" w="100%">
                <Text textSize="tiny" textTransform="uppercase" textColor="semiDark" textWeight="700"> {Lang(langVar).landing.pricing.label} </Text>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "0.5rem" }}> {Lang(langVar).landing.pricing.title} </Text>
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Text tag="h4" textAlign="left" textSize={{ xs: "display1", md: "display3" }} textWeight="700" textColor="dark" m={{ t: "4rem" }}>
                {Lang(langVar).landing.ending_title}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).landing.ending_subtitle}
            </Text>

            <Subscribe module={Lang('EN').landing.label} />

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>
        </>
    )
}

export default Landing

const Box: React.FC<{ width: number, title: string, text: string}> = ({ width, title, text }) => {

    return (
        <Div m={{ t: width > 700 ? "4rem" : "2rem" }}>
            <Text tag="h3" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500" m={{ b: "1rem" }}>
                    {title}
            </Text>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
                {text}
            </Text>
        </Div>
    )
}