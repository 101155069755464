const en = {
  label: "Relay",
  title: "Accelerate Your Transport Workflow with Cargobite’s Relay",
  subtitle: "Step into smarter logistics. Harness AI and integrations for a smoother transport management experience.",
  modules_title: "Modules Included in Relay:",
  modules_item1: "Order Recognition",
  modules_item2: "Inbound Sales AI",
  modules_item3: "3rd Party Integrations",
  automate_title: "Automate Your Order Process",
  recognition_title: "Intelligent Order Recognition",
  recognition_body: "Simplify order creation with our Order Recognition module. Automatically convert incoming orders from emails or texts into actionable tasks in your TMS, streamlining your workflow and reducing manual data entry.",
  sales_title: "Inbound Sales AI for Instant Pricing",
  sales_body: "Utilize AI to respond to transport requests with accurate pricing estimates instantly. Our Inbound Sales AI assesses request details and provides competitive, real-time quotes, enhancing your response rate and conversion potential.",
  expand_title: "Expand Your System’s Capabilities",
  integrations_title: "Seamless 3rd Party Integrations",
  integrations_body: "Connect with essential tools and platforms effortlessly. Our 3rd Party Integrations module allows you to create a network of automation flows, linking Cargobite TMS with other software you use, maximizing efficiency across all aspects of your operations.",
  leverage_title: "Leverage Advanced Technology for Operational Excellence",
  streamline_title: "Streamline Workflow with Automation",
  streamline_body: "Embrace automation across your operations, from order entry to pricing and beyond. Reduce human error, speed up your process, and focus on strategic tasks that grow your business.",
  decision_title: "Data-Driven Decision Making",
  decision_body: "Make informed decisions with insights powered by AI and seamless data exchange between systems. Optimize your operations based on accurate, real-time information.",
  customizable_title: "Customizable Solutions for Your Needs",
  customizable_body: "Tailor the Relay package to fit your unique logistics challenges. With customizable settings and scalable solutions, our system adapts to your business, ensuring you get the most out of every feature.",
  cta: "Elevate Your Logistics Operations with Cargobite’s Relay",
  cta_body: "Step into the future of transport logistics with Cargobite’s Relay. This package is not just about managing logistics; it’s about transforming how you do business in the transport sector. From AI-powered sales to comprehensive system integrations, Relay equips you with the tools to streamline your operations, respond faster to market demands, and secure a competitive edge. Join Cargobite today and start a new chapter in your logistics success story."
}
const si = {
  label: "Relay",
  title: "Pohitrite svoj transportni postopek s Cargobitejevim Relayem",
  subtitle: "Stopite v pametnejšo logistiko. Izkoristite umetno inteligenco in integracije za boljše doživetje upravljanja transporta.",
  modules_title: "Moduli vključeni v Relay:",
  modules_item1: "Prepoznavanje naročil",
  modules_item2: "Vhodna prodaja AI",
  modules_item3: "Integracije tretjih strank",
  automate_title: "Avtomatizirajte vaš postopek naročanja",
  recognition_title: "Pametno prepoznavanje naročil",
  recognition_body: "Poenostavite ustvarjanje naročil s našim modulom Prepoznavanje naročil. Samodejno pretvorite vhodna naročila iz e-pošte ali besedil v izvedljive naloge v vašem TMS, kar poenostavlja vaš delovni tok in zmanjšuje ročni vnos podatkov.",
  sales_title: "Vhodna prodaja AI za takojšnje cene",
  sales_body: "Izkoristite umetno inteligenco za odzivanje na transportne zahteve s takojšnjimi ocenami cen. Naša vhodna prodaja AI oceni podrobnosti zahteve in zagotovi konkurenčne, realnočasne ponudbe, izboljšuje vašo stopnjo odzivanja in potencial konverzije.",
  expand_title: "Razširite zmogljivosti vašega sistema",
  integrations_title: "Brezhibne integracije tretjih strank",
  integrations_body: "Povežite se z bistvenimi orodji in platformami brez napora. Naš modul Integracije tretjih strank vam omogoča, da ustvarite mrežo avtomatiziranih tokov, ki povezujejo Cargobite TMS z drugo programsko opremo, ki jo uporabljate, kar povečuje učinkovitost v vseh vidikih vašega poslovanja.",
  leverage_title: "Izkoristite napredno tehnologijo za operativno odličnost",
  streamline_title: "Poenostavite delovni tok z avtomatizacijo",
  streamline_body: "Sprejmite avtomatizacijo v celotnem vašem poslovanju, od vnašanja naročil do cen in še dlje. Zmanjšajte človeške napake, pospešite svoj proces in se osredotočite na strateške naloge, ki rastejo vaše podjetje.",
  decision_title: "Odločanje na podlagi podatkov",
  decision_body: "S podatki, ki jih napaja umetna inteligenca in brezhibna izmenjava podatkov med sistemi, sprejemajte informirane odločitve. Optimirajte svoje operacije na podlagi natančnih, realnočasnih informacij.",
  customizable_title: "Prilagodljive rešitve za vaše potrebe",
  customizable_body: "Prilagodite paket Relay, da se prilagodi vašim edinstvenim logističnim izzivom. S prilagodljivimi nastavitvami in razširljivimi rešitvami se naš sistem prilagodi vašemu poslovanju, zagotavljajoč, da boste iz vsake funkcije dobili največ.",
  cta: "Povečajte svoje logistične operacije s Cargobitejevim Relayem",
  cta_body: "Stopite v prihodnost logistike transporta s Cargobitejevim Relayem. Ta paket ni le o upravljanju logistike; gre za spreminjanje načina, kako poslujete v sektorju prevoza. Od AI podprtih prodaj do celovitih sistemskih integracij, Relay vas opremi z orodji, da poenostavite svoje operacije, hitreje odzivate na tržne zahteve in si zagotovite konkurenčno prednost. Pridružite se Cargobiteju še danes in začnite novo poglavje v vaši logistični uspešnici."
}

const langRelay = { en, si }

export default langRelay