const en = {
  label: "inbound sales AI for transporters",
  title: "Automate Your Sales Process with Inbound Sales AI",
  subtitle: "Leverage the power of AI to transform incoming transport requests into accurate, instant price estimates, optimizing your sales cycle and enhancing shipper communication.",
  revolutionize_title: "Revolutionize Your Sales with AI",
  analysis_title: "Instant Analysis of Transport Requests",
  analysis_body: "Utilize AI to swiftly analyze incoming emails and messages, identifying potential transport requests with precision. Our Inbound Sales AI captures the essence of each request, preparing your system to respond effectively.",
  calculation_title: "Automated Cost Calculation",
  calculation_body: "Say goodbye to manual estimations. Based on the identified order paths and your unique pricing settings, the AI calculates the transportation cost automatically, ensuring accuracy and consistency in every quote.",
  communication_title: "Efficient Shipper Communication",
  communication_body: "Streamline your interactions with shippers. Once the estimate is ready, our system communicates the expected cost directly, providing a seamless and professional experience for your clients.",
  enhance_title: "Enhance Efficiency and Accuracy",
  pricing_title: "Tailored Pricing Settings",
  pricing_body: "Customize how your costs are calculated. Adjust the AI to factor in your specific pricing strategies, from fuel surcharges to seasonal adjustments, ensuring every estimate reflects your business model.",
  integration_title: "Seamless Integration with Your Workflow",
  integration_body: "Our Inbound Sales AI isn’t just smart; it’s compatible. Seamlessly integrate with your existing sales and operational workflows, ensuring a smooth transition from quote to order without missing a beat.",
  insights_title: "Data-Driven Insights for Continuous Improvement",
  insights_body: "Benefit from actionable insights. Monitor the AI’s performance and the accuracy of estimates, using this data to refine your pricing strategies and improve shipper satisfaction over time.",
  bundle: {
    first: "Use Inbound Sales AI module as standalone application or bundle it together with ",
    last: " & other integrations modules in our Relay product.",
    recognition: "Order Recognition",
    sales: "Inbound Sales AI"
  },
  bundle_btn: "View Relay product →",
  cta: "Transform Your Sales Strategy with Cargobite’s Inbound Sales AI",
  cta_body: "Embrace a smarter way to handle your sales process. Cargobite’s Inbound Sales AI is here to ensure your transport business not only meets the modern market's demands but exceeds them, turning every inquiry into a potential job with unmatched efficiency and precision."
}
const si = {
  label: "vhodna prodaja AI za prevoznike",
  title: "Avtomatizirajte svoj prodajni proces z vhodno prodajo AI",
  subtitle: "Izkoristite moč umetne inteligence, da preoblikujete prihajajoče zahteve za prevoz v natančne, takojšnje cenovne ocene, optimizirate svoj prodajni cikel in izboljšate komunikacijo s pošiljatelji.",
  revolutionize_title: "Revolucionirajte svojo prodajo z AI",
  analysis_title: "Takojšnja analiza zahtev za prevoz",
  analysis_body: "Uporabite AI, da hitro analizirate prihajajoče e-poštne sporočila in sporočila ter natančno prepoznate potencialne zahteve za prevoz. Naša vhodna prodaja AI zajame bistvo vsake zahteve, pripravlja vaš sistem, da učinkovito odgovori.",
  calculation_title: "Avtomatizirani izračun stroškov",
  calculation_body: "Oprostite se ročnim ocenam. Na podlagi prepoznanih poti naročil in vaših edinstvenih cenovnih nastavitev AI samodejno izračuna stroške prevoza, zagotavljajoč natančnost in doslednost v vsaki ponudbi.",
  communication_title: "Učinkovita komunikacija s pošiljatelji",
  communication_body: "Poenostavite svoje interakcije s pošiljatelji. Ko je ocena pripravljena, naš sistem neposredno sporoči pričakovane stroške, zagotavljajoč nemoten in strokoven izkušnjo za vaše stranke.",
  enhance_title: "Povečajte učinkovitost in natančnost",
  pricing_title: "Prilagojene cenovne nastavitve",
  pricing_body: "Prilagodite, kako se izračunajo vaši stroški. Prilagodite AI, da upošteva vaše specifične cenovne strategije, od doplačil za gorivo do sezonskih prilagoditev, zagotavljajoč, da vsaka ocena odraža vaš poslovni model.",
  integration_title: "Brezhibna integracija s vašim delovnim tokom",
  integration_body: "Naša vhodna prodaja AI ni le pametna; je združljiva. Sezonsko integrirajte v svoje obstoječe prodajne in operativne delovne tokove, zagotavljajoč gladko prehajanje od ponudbe do naročila brez izgube ritma.",
  insights_title: "Podatkovno vodene ugotovitve za neprekinjeno izboljševanje",
  insights_body: "Izkoristite koristne ugotovitve. Spremljajte delovanje AI in natančnost ocen, uporabite te podatke za izboljšanje vaših cenovnih strategij in sčasoma izboljšajte zadovoljstvo pošiljateljev.",
  bundle: {
    first: "Uporabite modul za Vhodno Prodajo AI kot samostojno aplikacijo ali ga združite s ",
    last: " & drugimi moduli integracij v našem izdelku Relay.",
    recognition: "Prepoznavanjem Naročil",
    sales: "Vhodno Prodajo AI"
  },
  bundle_btn: "Ogled izdelka Relay →",
  cta: "Preoblikujte svojo prodajno strategijo z vhodno prodajo AI Cargobite",
  cta_body: "Sprejmite pametnejši način ravnanja s svojim prodajnim procesom. Vhodna prodaja AI Cargobite je tu, da zagotovi, da vaše prevozno podjetje ne le zadovolji zahteve sodobnega trga, temveč jih preseže, vsako povpraševanje spremeni v potencialno nalogo z neprimerljivo učinkovitostjo in natančnostjo."
}

const langInboundSales = { en, si }

export default langInboundSales