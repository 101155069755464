/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useState } from 'react'
import * as Styled from "./Header.styles"
import { Div, Icon, Text, Anchor } from "atomize";
import { Link, useParams } from "react-router-dom";
import renderIcon from '../../assets/Icons';
import useWindowDimensions from '../../helpers/CustomHooks/useWindowDimensions';
import Border from '../../components/Border/Border';
import Lang from '../../helpers/Text/Lang';
import Skeleton from '../../components/Skeleton/Skeleton';
import routeConfig from '../../config/routeConfig';
import { useTheme } from 'styled-components';
import cargobite_tms from "../../assets/images/cargobite_tms.png"
import cargobite_automation from "../../assets/images/cargobite_automation.png"
import { LangVarContext } from '../../App';
import { config } from '../../config';

const Header: React.FC<{
    token: string | null,
    isLoading: boolean,
}> = ({
    token,
    isLoading}) => {
        const { langVar, setLangVar } = useContext(LangVarContext)
        const { width, height } = useWindowDimensions()

        const [open, setOpen] = useState<boolean>(false)
        const [blur, setBlur] = useState<boolean>(false)

        window.addEventListener("scroll", function (e: Event) {
            if (window.scrollY > 0) {
                setBlur(true)
            } else {
                setBlur(false)
            }
        }, true)

        return (
            <>
                <Styled.Header>
                    <Div style={{ zIndex: "101" }} left="0" w="100%" pos="fixed" className={blur && "header"}>
                        <Div d="flex" h="56px" w="100%" p={{ x: "1rem" }} align="center" justify="space-between"   >
                            <Link
                                to="/"
                                style={{
                                    cursor: 'pointer',
                                    height: '24px',
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                                className="logo_wrapper"
                                onClick={() => { setOpen(false) }}
                            >
                                {renderIcon("Logo")}
                            </Link>
                            <Div
                                pos="absolute"
                                left="50%"
                                top="50%"
                                transform="translate(-50%, -50%)"
                                d={width > 522 ? "flex" : "none"}
                                align="center"
                            >
                                <Dropdown type="packages" langVar={langVar} />
                                <Dropdown type="modules" langVar={langVar} />
                            </Div>
                            <Div d={width > 522 ? "flex" : "none"} align="center">
                                <Text hoverTextColor="dark" m={{ r: "1rem" }} d="flex" align="center" transition textColor="semiDark" onClick={() => { setLangVar(langVar === "EN" ? "SI" : langVar === "SI" ? "EN" : langVar) }} textSize={12} textWeight="500" cursor="pointer" >
                                    <span>{langVar}</span>
                                    <span style={{ width: "18px", display: "flex", marginLeft: "0.5rem" }}>{renderIcon(langVar === "EN" ? "EnIcon" : "SiIcon")}</span>
                                </Text>

                                {isLoading ?
                                    <Skeleton height="24px" width="120px" />
                                    :
                                    token ?
                                    <Anchor href={`${config.app_site_url}/signin`} className='link'>
                                        <Text >
                                            {Lang(langVar).go_to_app}
                                        </Text>
                                    </Anchor>
                                    :
                                    <>
                                        <Anchor href={`${config.app_site_url}/signin`} className='link'>
                                            <Text>
                                                {Lang(langVar).log_in}
                                            </Text>
                                        </Anchor>
                                        <Anchor
                                            href="https://calendly.com/blazcargobite/introduction"
                                            target="_blank"
                                            className='link last_link'
                                        >
                                            <Text>
                                                {Lang(langVar).btn.book_call}
                                            </Text>
                                        </Anchor>
                                    </>
                                }
                            </Div>

                            <Icon name={!open ? "Menu" : "Cross"} d={width > 522 ? "none" : "block"} pos="fixed" right="1rem" top="1.2rem" size="20px" style={{ zIndex: "101" }} hoverColor="dark" color="semiDark" cursor="pointer" onClick={() => {
                                setOpen(!open)
                            }} />
                        </Div>
                        <Div w="calc(100% - 2rem)" m={{ x: "1rem" }}>
                            <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                        </Div>
                    </Div>
                    {width <= 522 && (
                        <Div pos="fixed" bg="background" w="100%" right="0" top="0" h={`${height}px`} style={{ zIndex: "100", transform: `translateX(${open ? "0" : "100%"})`, overflowY: "auto" }} transition>
                            <Div d="flex" flexDir="column" p={{ t: "5rem", x: "1rem", b: "1rem" }}>
                                <Dropdown type="packages" langVar={langVar} setOpen={setOpen} isMobile />
                                <Dropdown type="modules" langVar={langVar} setOpen={setOpen} isMobile />
                                <Anchor
                                    href="mailto:info@cargobite.com"
                                    target="_blank"
                                    className='link_mobile first_link'
                                    m={{ t: "0.25rem" }}
                                >
                                    <Text>
                                        {Lang(langVar).get_in_touch}
                                    </Text>
                                </Anchor>
                                {token ?
                                    <Anchor
                                        href={`${config.app_site_url}/signin`}
                                        className="link_mobile"
                                    >
                                        <Text>
                                            {Lang(langVar).go_to_app}
                                        </Text>
                                    </Anchor>
                                    :
                                    <>
                                        <Anchor
                                            href={`${config.app_site_url}/signin`}
                                            className="link_mobile"
                                        >
                                            <Text>
                                            {Lang(langVar).log_in}
                                            </Text>
                                        </Anchor>
                                        <Anchor
                                            href="https://calendly.com/blazcargobite/introduction"
                                            target="_blank"
                                            className="link_mobile last_link"
                                        >
                                            <Text>
                                                {Lang(langVar).btn.book_call}
                                            </Text>
                                        </Anchor>
                                    </>
                                }
                                <Text cursor="pointer" hoverTextColor="dark" d="flex" align="center" transition textColor="semiDark" onClick={() => {
                                    setOpen(false)
                                    setLangVar(langVar === "EN" ? "SI" : "EN")
                                }} textSize={16} textWeight="500" >
                                    <span>{langVar}</span>
                                    <span className='langIcon' >{renderIcon(langVar === "EN" ? "EnIcon" : "SiIcon")}</span>
                                </Text>
                            </Div>

                        </Div>
                    )}
                </Styled.Header>
                <Div h="57px"> </Div>
            </>
        )
}

export default Header

const Dropdown: React.FC<{ 
    type: string,
    langVar: "EN" | "SI"
    isMobile?: boolean
    setOpen?: (value: boolean) => void
}> = ({
    type,
    langVar,
    isMobile = false,
    setOpen = () => {},
}) => {
    const theme = useTheme()
    const [isHover, setIsHover] = useState<boolean>(false)
    const { moduleName, productName } = useParams<{ moduleName?: string, productName?: string }>()

    return (
        <Div pos="relative" m={{ r: isMobile ? "0" : "1rem"}} onMouseEnter={!isMobile ? () => setIsHover(true) : undefined} onMouseLeave={!isMobile ? () => setIsHover(false) : undefined} onClick={() => setIsHover(!isHover)} >
            <Text
                d="flex"
                align="center"
                cursor="pointer"
                textColor={isHover ? "dark" : "semiDark"}
                bg={isHover ? "grey200" : "transparent"}
                textSize={isMobile ? 16 : 14}
                p={{ y: "0.25rem", l: "0.25rem", r: "0.5rem"}}
                m={{ x: isMobile ? "-0.25rem" : ""}}
                rounded={6}
                textWeight="500"
                transition="color 0.3s ease-in-out, background-color 0.3s ease-in-out"
            >
                {type === "packages" && Lang(langVar).packages}
                {type === "modules" && Lang(langVar).modules}
                <Icon
                    name="DownArrow"
                    m={{ l: "5px" }}
                    size="18px"
                    color="semiDark"
                    transform={isHover ? "rotate(180deg)" : ""}
                    style={{ transition: "transform 0.3s ease-in-out" }}
                />
            </Text>
            <Div
                d="flex"
                pos={ isMobile ? "relative" : "absolute" }
                top={ isMobile ? "0" : "100%" }
                left="0"
                w={ isMobile ? "100%" : "260px"}
                p={{ t: "0.5rem"}}
                style={{
                    pointerEvents: isHover ? "all" : "none",
                    touchAction: isHover ? "auto" : "none"
                }}
            >
                <Div
                    d={isHover ? "flex" : isMobile ? "none" : "flex"}
                    flexDir={ isMobile ? "column" : type === "packages" ? "column" : "row" }
                    w={ isMobile ? "100%" : ""}
                    bg={isMobile ? "" : "white"}
                    rounded={6}
                    p={{ x: isMobile ? "0" : "0.5rem", y: "0.5rem" }}
                    transform={isMobile ? "rotateX(0deg)" : isHover ? "rotateX(0deg)" : "rotateX(90deg)"}
                    transformOrigin="top"
                    transition={ isMobile ? '' : "transform 0.3s ease-in-out"}
                    style={{ boxShadow: isMobile ? "" : "0px 6px 6px 0px rgba(0, 0, 0, 0.1)", willChange: "transform" }}
                >
                    {type === "packages" && Lang(langVar).packages_items.map((item, index) => 
                            <Div
                                key={`modules-transporters-${index}`}
                                p={{ b: "0.5rem" }}
                            >
                                {index > 0 && <Border margin='0 0 0.5rem' ></Border>}
                                <Link
                                    to={routeConfig[item.type as keyof typeof routeConfig].url}
                                    style={{ display: "flex", alignItems: "center", color: productName === item.type ? theme?.colors?.brand600 : theme?.colors?.dark, transition: "color 0.3s ease-in-out" }}
                                    onMouseEnter={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                        if (productName !== item.type)
                                        (e.currentTarget as HTMLAnchorElement).style.color = theme?.colors?.brand600;
                                    }}
                                    onMouseLeave={(e: React.MouseEvent<HTMLAnchorElement>) => {
                                        if (productName !== item.type)
                                        (e.currentTarget as HTMLAnchorElement).style.color = theme?.colors?.dark; 
                                    }}
                                    onClick={() => {
                                        setIsHover(false)
                                        setOpen(false)
                                    }}
                                >
                                    {item.type === 'tms' && <img src={cargobite_tms} style={{ width: "24px", marginRight: "1rem" }} alt="cargobite_tms" />}
                                    {item.type === 'relay' && <img src={cargobite_automation} style={{ width: "24px", marginRight: "1rem" }} alt="cargobite_tms" />}
                                    <Div
                                        d="flex"
                                        align="flex-start"
                                        flexDir="Column"
                                    >
                                        <Text textSize={isMobile ? 16 : 14} textWeight="700" m={{ b: "0.125rem"}} style={{ whiteSpace: 'nowrap' }}>
                                            {item.name}
                                        </Text>
                                        <Text
                                            textSize={12}
                                            textWeight="700"
                                        >
                                            <span>{item.modules_text}: </span>
                                            <span style={{ color: theme?.colors?.light, fontWeight: 500 }}>{item.modules_list}</span>
                                        </Text>
                                    </Div>
                                </Link>
                            </Div>
                        )}
                    {type === "modules" &&  (
                    <>
                        <Div m={{ b: isMobile ? "1rem" : "0" }}>
                            <Text textSize="tiny" textTransform="uppercase" textColor="light" textWeight="700" m={{ b: "0.5rem" }} style={{ whiteSpace: 'nowrap' }}>
                                {Lang(langVar).for_transporters}
                            </Text>
                            {Lang(langVar).modules_items.transporters.map((item, index) => 
                                <Div
                                    key={`modules-transporters-${index}`}
                                    p={{ b: "0.5rem" }}
                                >
                                    <Border margin='0 0 0.5rem' ></Border>
                                    <Link
                                        to={routeConfig[item.type as keyof typeof routeConfig].url}
                                        style={{ display: "flex", alignItems: "center" }}
                                        onClick={() => {
                                            setIsHover(false)
                                            setOpen(false)
                                        }}
                                    >
                                        <Icon
                                            name={(routeConfig[item.type as keyof typeof routeConfig] as any)?.icon ?? ''}
                                            m={{ r: "0.25rem" }}
                                            size="16px"
                                            color="success"
                                            style={{ transition: "transform 0.3s ease-in-out" }}
                                        />
                                        <Text textSize={isMobile ? 16 : 14} textWeight="500" textColor={moduleName === item.type ? "success" : "dark"} hoverTextColor="success" transition="all 0.3s ease-in-out" style={{ whiteSpace: 'nowrap' }}>
                                            {item.name}
                                        </Text>
                                    </Link>
                                </Div>
                            )}
                        </Div>
                        <Div m={{ b: isMobile ? "-0.25rem" : "0", l: isMobile ? "0" : "2rem" }}>
                            <Text textSize="tiny" textTransform="uppercase" textColor="light" textWeight="700" m={{ b: "0.5rem" }} style={{ whiteSpace: 'nowrap' }}>
                                {Lang(langVar).for_shippers}
                            </Text>
                            {Lang(langVar).modules_items.shippers.map((item, index) => 
                                <Div
                                    key={`modules-transporters-${index}`}
                                    p={{ b: "0.5rem" }}
                                >
                                    <Border margin='0 0 0.5rem' ></Border>
                                    <Link
                                        to={routeConfig[item.type as keyof typeof routeConfig].url}
                                        style={{ display: "flex", alignItems: "center" }}
                                        onClick={() => {
                                            setIsHover(false)
                                            setOpen(false)
                                        }}
                                    >
                                        <Icon
                                            name={(routeConfig[item.type as keyof typeof routeConfig] as any)?.icon}
                                            m={{ r: "0.25rem" }}
                                            size="16px"
                                            color="warning"
                                            style={{ transition: "transform 0.3s ease-in-out" }}
                                        />
                                        <Text textSize={isMobile ? 16 : 14} textWeight="500" textColor={moduleName === item.type ? "warning" : "dark"} hoverTextColor="warning" transition="all 0.3s ease-in-out" style={{ whiteSpace: 'nowrap' }}>
                                            {item.name}
                                        </Text>
                                    </Link>
                                </Div>
                            )}
                        </Div>
                    </>
                    )}
                </Div>
            </Div>
        </Div>
    )
}