const en = {
  label: "order management for transporters",
  title: "Take your transport business higher with Cargobite",
  subtitle: "Discover how our platform streamlines operations, empowers teams, and boosts profitability in the logistics sector.",
  streamline: "Streamline your order process",
  setup_title: "Efficient order setup",
  setup_body: "Quickly line up new transport jobs with ease. Our platform cuts through the complexity, enabling fast management of requests and growth of your operations.",
  realtime_title: "Real-time job tracking",
  realtime_body: "Always know the status of your transports. Our real-time updates ensure you and your clients stay informed, building trust in your reliability.",
  simplified_title: "Simplified document handling",
  simplified_body: "Keep all key documents at your fingertips. Say goodbye to lost paperwork with our secure, easy-to-navigate document storage solution.",
  optimize_title: "Optimize operations and gain control",
  best_title: "Find the best rates easily",
  best_body: "Maximize profits by accessing competitive rates. Our marketplace connects you with a variety of requests, helping you choose the most profitable jobs.",
  custom_title: "Custom permissions for your team",
  custom_body: "Give your team the access they need. From dispatchers to drivers, our platform allows for efficient role assignment and permissions management.",
  structured_title: "Structured planning for your fleet",
  structured_body: "Plan your operations with precision. Our tools support complex order management, enabling better scheduling and fleet utilization.",
  collaborate: "Collaborate and comply with ease",
  never_title: "Never miss a deadline again",
  never_body: "Keep on top of tasks with smart reminders. Our system helps you manage schedules and maintain compliance without the hassle.",
  seamless_title: "Seamless communication with clients",
  seamless_body: "Share information effortlessly. Our platform makes updating clients simple, ensuring clarity and satisfaction.",
  direct_title: "Direct collection of driver documentation",
  direct_body: "Ease compliance with our Driver’s App. Collect necessary paperwork directly from drivers, streamlining document management and meeting regulatory needs.",
  bundle: {
    first: "Use order management module as standalone application or bundle it together with ",
    last: " modules in our TMS product.",
    document: "document issuing",
    fleet: "fleet tracking"
  },
  bundle_btn: "View TMS product →",
  cta: "Revolutionize your transport business",
  cta_body: "Join Cargobite today. Leverage our order management tool to streamline, optimize, and expand your transport services. With Cargobite, advancing your business is simpler than ever. You're not just moving goods — you're advancing towards a brighter future."
}
const si = {
  label: "upravljanje naročil za prevoznike",
  title: "Ponesite svoje prevozno podjetje višje z Cargobite",
  subtitle: "Odkrijte, kako naša platforma poenostavlja operacije, osnažuje ekipe in povečuje dobičkonosnost v logističnem sektorju.",
  streamline: "Poenostavite svoj postopek naročanja",
  setup_title: "Učinkovita nastavitev naročil",
  setup_body: "Hitro uskladite nove prevozne naloge z lahkoto. Naša platforma prebija skozi kompleksnost, omogoča hitro upravljanje zahtev in rast vaših operacij.",
  realtime_title: "Sledenje nalog v realnem času",
  realtime_body: "Vedno vedite, v kakšnem stanju so vaši prevozi. Naši posodobitve v realnem času zagotavljajo, da vi in vaši stranki ostajate obveščeni, kar gradi zaupanje v vašo zanesljivost.",
  simplified_title: "Poenostavljeno upravljanje dokumentov",
  simplified_body: "Vse ključne dokumente imejte pri roki. S poslovnim skladiščenjem dokumentov, ki je varno in enostavno za navigacijo, se lahko poslovite od izgubljenih dokumentov.",
  optimize_title: "Optimizacija operacij in pridobitev nadzora",
  best_title: "Enostavno najdite najboljše tarife",
  best_body: "Povečajte dobiček z dostopom do konkurenčnih tarif. Naša tržnica vas poveže z različnimi zahtevami in vam pomaga izbrati najbolj donosne naloge.",
  custom_title: "Prilagojena dovoljenja za vašo ekipo",
  custom_body: "Nudite svoji ekipi dostop, ki ga potrebujejo. Od dispečerjev do voznikov naša platforma omogoča učinkovito dodeljevanje vlog in upravljanje dovoljenj.",
  structured_title: "Strukturirano načrtovanje za vaš vozni park",
  structured_body: "Načrtujte svoje operacije z natančnostjo. Naša orodja podpirajo kompleksno upravljanje naročil, kar omogoča boljše načrtovanje in izrabo vozil.",
  collaborate: "Sodelujte in se enostavno skladajte",
  never_title: "Nikoli več ne zamudite roka",
  never_body: "Ostajajte na vrhu nalog z inteligentnimi opomniki. Naš sistem vam pomaga upravljati urnike in ohranjati skladnost brez težav.",
  seamless_title: "Brezhibno komuniciranje s strankami",
  seamless_body: "Delite informacije brez napora. Naša platforma posodabljanje strankam poenostavi, zagotavljajoč jasnost in zadovoljstvo.",
  direct_title: "Neposredno zbiranje voznikove dokumentacije",
  direct_body: "Lažje izpolnjujte zahteve s našo aplikacijo za voznike. Potrebno dokumentacijo zbirajte neposredno od voznikov, kar poenostavlja upravljanje dokumentov in izpolnjevanje regulatornih zahtev.",
  bundle: {
    first: "Uporabite modul za upravljanje naročil kot samostojno aplikacijo ali ga združite s moduli za ",
    last: " v našem izdelku TMS.",
    document: "izdajanje dokumentov",
    fleet: "sledenje vozil"
  },
  bundle_btn: "Ogled izdelka TMS →",
  cta: "Revolucionirajte svoje prevozno podjetje",
  cta_body: "Pridružite se Cargobite danes. Izkoristite naše orodje za upravljanje naročil, da poenostavite, optimizirate in razširite svoje prevozne storitve. Z Cargobite je napredovanje vašega podjetja lažje kot kdaj koli prej. Ne premikate samo blaga — napredujete proti svetlejši prihodnosti."
}

const langOrderManagmentTransporters = { en, si }

export default langOrderManagmentTransporters