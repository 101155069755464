import { Helmet } from 'react-helmet';
import React, { useContext } from 'react'
import relay from "../../assets/images/relay.png"
import { Text, Div, Icon } from "atomize";
import usePreloadImages from '../../helpers/CustomHooks/usePreloadImages';
import useWindowDimensions from '../../helpers/CustomHooks/useWindowDimensions';
import Lang from '../../helpers/Text/Lang';
import Border from '../../components/Border/Border';
import Subscribe from '../../components/Subscribe/Subscribe';
import { LangVarContext } from '../../App';

const Relay: React.FC<{}> = () => {
    const { langVar } = useContext(LangVarContext)
    usePreloadImages(false)
    const { width } = useWindowDimensions()

    return (
        <>
            <Helmet>
                <title>Relay Tool for Logistics | Cargobite</title>
                <meta content="Relay Tool for Logistics | Cargobite" property="og:title" />
                <meta content="Relay Tool for Logistics | Cargobite" name="twitter:title" />
                <meta content="Step into smarter logistics. Harness AI and integrations for a smoother transport management experience." name="description" />
                <meta content="Step into smarter logistics. Harness AI and integrations for a smoother transport management experience." property="og:description" />
                <meta content="Step into smarter logistics. Harness AI and integrations for a smoother transport management experience." name="twitter:description" />
            </Helmet>
            <Div>
                <Text tag="h3" textSize="tiny" textAlign="left"  textTransform="uppercase" textColor="brand600" textWeight="700" width={width} m={{ t: width > 700 ? "4rem" : "1.5rem" }}>
                    {Lang(langVar).relay.label}
                </Text>
                <Text tag="h1" textAlign="left" textSize={{ xs: "display2", md: "display3" }} textWeight="700" textColor="dark" m={{ t: width > 700 ? "1rem" : "0.25rem" }}>
                    {Lang(langVar).relay.title}
                </Text>
                <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                    {Lang(langVar).relay.subtitle}
                </Text>

                <Subscribe module={Lang('EN').relay.label} />
            </Div>

            <img src={relay} style={{ width: width > 600 ? "calc(100% + 32rem)" : "calc(100% + 14rem)", margin: width > 600 ? "7.75rem -6rem 0" : "3.75rem -7rem 0"}} alt="" />
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).relay.modules_title} </Text>
                
                <Div d="flex" flexDir="row" align="center" w="100%" m={{ t: width > 700 ? "1rem": "0.5rem" }} >
                  <Icon name="Dot" color="brand600" size="20px" m={{ r: "0.25rem" }} />
                  <Text tag="h2" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500">
                    {Lang(langVar).relay.modules_item1}
                  </Text>
                </Div>
                <Div d="flex" flexDir="row" align="center" w="100%" m={{ t: width > 700 ? "1rem": "0.5rem" }} >
                  <Icon name="Dot" color="brand600" size="20px" m={{ r: "0.25rem" }} />
                  <Text tag="h2" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500">
                    {Lang(langVar).relay.modules_item2}
                  </Text>
                </Div>
                <Div d="flex" flexDir="row" align="center" w="100%" m={{ t: width > 700 ? "1rem": "0.5rem" }} >
                  <Icon name="Dot" color="brand600" size="20px" m={{ r: "0.25rem" }} />
                  <Text tag="h2" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500">
                    {Lang(langVar).relay.modules_item3}
                  </Text>
                </Div>

                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>

            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).relay.automate_title} </Text>
                <Box width={width} title={Lang(langVar).relay.recognition_title} text={Lang(langVar).relay.recognition_body}/>
                <Box width={width} title={Lang(langVar).relay.sales_title} text={Lang(langVar).relay.sales_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>

            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).relay.expand_title} </Text>
                <Box width={width} title={Lang(langVar).relay.integrations_title} text={Lang(langVar).relay.integrations_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}>
                    <Border color='rgba(0, 0, 0, 0.1)' ></Border>
                </Div>
            </Div>
            
            <Div d="flex" flexDir="column" align="flex-start" w="100%" m={{ t: width > 700 ? "4rem": "0" }}>
                <Text tag="h2" textSize={{ xs: "heading", md: "display2" }} textColor="dark" textWeight="700" m={{ t: "4rem" }}> {Lang(langVar).relay.leverage_title} </Text>
                <Box width={width} title={Lang(langVar).relay.streamline_title} text={Lang(langVar).relay.streamline_body}/>
                <Box width={width} title={Lang(langVar).relay.decision_title} text={Lang(langVar).relay.decision_body}/>
                <Box width={width} title={Lang(langVar).relay.customizable_title} text={Lang(langVar).relay.customizable_body}/>
                <Div w="100%" m={{ t: width > 700 ? "4rem": "2rem" }}></Div>
            </Div>

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: width > 700 ? "4rem" : "2rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>

            <Text tag="h4" textAlign="left" textSize={{ xs: "display1", md: "display3" }} textWeight="700" textColor="dark" m={{ t: "4rem" }}>
                {Lang(langVar).relay.cta}
            </Text>
            <Text textAlign="left" textSize={{ xs: "paragraph", md: "subheader" }} textWeight="400" textColor="semiDark" m={{ t: width > 700 ? "2rem" : "1rem", b: width > 700 ? "2rem" : "1rem" }}>
                {Lang(langVar).relay.cta_body}
            </Text>

            <Subscribe module={Lang('EN').relay.label} />

            <Div w="calc(100vw - 2rem)" m={{ x: "1rem", t: "4rem" }}>
                <Border color='rgba(0, 0, 0, 0.1)' ></Border>
            </Div>
        </>
    )
}

export default Relay

const Box: React.FC<{ width: number, title: string, text: string}> = ({ width, title, text }) => {

    return (
        <Div m={{ t: width > 700 ? "4rem" : "2rem" }}>
            <Text tag="h3" textSize={{ xs: "subheader", md: "title" }} textColor="dark" textWeight="500" m={{ b: "1rem" }}>
                    {title}
            </Text>
            <Text textSize={{ xs: "body", md: "paragraph" }} textColor="semiDark">
                {text}
            </Text>
        </Div>
    )
}