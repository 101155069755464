const en = {
  label: "TMS",
  title: "Upgrade Your Transport Operations with Cargobite’s TMS",
  subtitle: "Dive into how our Transport Management System simplifies logistics, from seamless order management to live fleet tracking, all while saving you time and money.",
  modules_title: "Modules included in TMS:",
  modules_item1: "Order Management",
  modules_item2: "Document Issuing & Invoicing",
  modules_item3: "Fleet Tracking",
  order_management_title: "Centralize Your Order Management",
  setup_title: "Streamlined Order Setup",
  setup_body: "Initiate transport jobs swiftly, leveraging our platform to cut through operational complexities and foster growth.",
  tracking_title: "Real-Time Job Tracking",
  tracking_body: "Gain transparency with live updates, keeping you and your clients informed and building trust through reliability.",
  document_handling_title: "Simplified Document Handling",
  document_handling_body: "Maintain easy access to crucial documents, eliminating paperwork losses with our secure, navigable storage solution.",
  enhance_title: "Enhance Operational Efficiency and Control",
  rate_access_title: "Competitive Rate Access",
  rate_access_body: "Secure the most profitable transport jobs by tapping into our extensive marketplace for competitive rates.",
  permissions_title: "Customizable Team Permissions",
  permissions_body: "Allocate specific roles and permissions within your team, from dispatchers to drivers, ensuring smooth operations.",
  planning_title: "Fleet Planning and Utilization",
  planning_body: "Employ our sophisticated tools for intricate order management and fleet scheduling, optimizing every move.",
  streamline_title: "Streamline Compliance and Communication",
  automation_title: "Automated Document Management",
  automation_body: "From invoicing to compliance, automate your documentation processes, customizing invoices with VAT percentages and ensuring seamless integration with accounting systems.",
  estimates_title: "Proactive Payment Estimates",
  estimates_body: "Keep ahead of your finances with estimates on incoming payments, based on invoice due dates.",
  bulk_invoicing_title: "Efficient Bulk Invoicing",
  bulk_invoicing_body: "Streamline your billing process with capabilities for managing and sending bulk invoices, coupled with secure, private link sharing with clients.",
  innovate_title: "Innovate with Fleet Tracking and Management",
  tracking_implementation_title: "Device-Free Live Tracking",
  tracking_implementation_body: "Implement live fleet tracking effortlessly, without the need for additional hardware, offering a cost-effective, comprehensive visibility solution.",
  compliance_automation_title: "Document Compliance Automation",
  compliance_automation_body: "Automate compliance, managing and renewing necessary documents with ease, supported by proactive alerts to ensure uninterrupted, compliant operations.",
  insights_title: "Operational Insights",
  insights_body: "Utilize real-time data to refine fleet management strategies, from optimizing routes to managing driver schedules, elevating efficiency, and operational intelligence.",
  cta: "Level Up Your Transport and Logistics with Cargobite",
  cta_body: "Join the Cargobite family and harness the power of our TMS to unlock unparalleled efficiencies in your logistics operations. Our TMS package not only simplifies your workflows but also empowers you to scale your operations, enhance profitability, and forge stronger, more reliable supply chain relationships. With Cargobite, you’re not just managing logistics — you’re setting new benchmarks in the transport sector."
}
const si = {
  label: "TMS",
  title: "Nadgradite svoje prevozne operacije z Cargobitejevim sistemom upravljanja prevoza",
  subtitle: "Poglobite se v to, kako naš sistem upravljanja prevoza poenostavlja logistiko, od brezhibnega upravljanja naročil do sledenja v realnem času, pri tem pa prihranite čas in denar.",
  modules_title: "Moduli, vključeni v TMS:",
  modules_item1: "Upravljanje naročil",
  modules_item2: "Izdajanje dokumentov in izdajanje računov",
  modules_item3: "Sledenje vozil",
  order_management_title: "Centralizirajte svoje upravljanje naročil",
  setup_title: "Po verejnost nastavitve naročil",
  setup_body: "Začnite prevozne naloge hitro, s pomočjo naše platforme, ki se prebija skozi operativne kompleksnosti in spodbuja rast.",
  tracking_title: "Sledenje nalog v realnem času",
  tracking_body: "Pridobite preglednost z živimi posodobitvami, ki vas in vaše stranke obveščajo in gradijo zaupanje z zanesljivostjo.",
  document_handling_title: "Poenostavljeno upravljanje dokumentov",
  document_handling_body: "Ohranite enostaven dostop do ključnih dokumentov, s čimer odpravite izgubo dokumentacije z našo varno, pregledno shrambo.",
  enhance_title: "Izboljšajte operativno učinkovitost in nadzor",
  rate_access_title: "Dostop do konkurenčnih cen",
  rate_access_body: "Zagotovite si najbolj donosne prevozne naloge z izkoriščanjem našega obsežnega trga za konkurenčne cene.",
  permissions_title: "Prilagodljiva dovoljenja za ekipo",
  permissions_body: "Dodelite specifične vloge in dovoljenja znotraj vaše ekipe, od dispečerjev do voznikov, zagotavljajoč gladko delovanje.",
  planning_title: "Načrtovanje in izraba voznega parka",
  planning_body: "Uporabite naša napredna orodja za zapleteno upravljanje naročil in razporejanje voznega parka, optimizirajte vsak premik.",
  streamline_title: "Poenostavite skladnost in komunikacijo",
  automation_title: "Avtomatizirano upravljanje dokumentov",
  automation_body: "Od izdajanja računov do skladnosti, avtomatizirajte svoje postopke dokumentiranja, prilagodite račune z DDV stopnjami in zagotovite brezhibno integracijo z računovodskimi sistemi.",
  estimates_title: "Proaktivne ocene plačil",
  estimates_body: "Ostanite korak pred svojimi financami z ocenami prihodnjih plačil, ki temeljijo na rokih izdaje računov.",
  bulk_invoicing_title: "Učinkovito množično izdajanje računov",
  bulk_invoicing_body: "Poenostavite svoj postopek zaračunavanja z možnostmi za upravljanje in pošiljanje množičnih računov, skupaj s varnim, zasebnim deljenjem povezav s strankami.",
  innovate_title: "Inovirajte z sledenjem in upravljanjem voznega parka",
  tracking_implementation_title: "Sledenje v realnem času brez naprav",
  tracking_implementation_body: "Uvedite sledenje voznega parka v realnem času brez dodatne strojne opreme, kar omogoča stroškovno učinkovito, celovito rešitev za preglednost.",
  compliance_automation_title: "Avtomatizacija skladnosti dokumentov",
  compliance_automation_body: "Avtomatizirajte skladnost, upravljajte in obnavljajte potrebne dokumente brez težav, podprto s proaktivnimi opozorili, ki zagotavljajo neprekinjeno, skladno delovanje.",
  insights_title: "Operativni vpogledi",
  insights_body: "Izkoristite podatke v realnem času za izpopolnitev strategij upravljanja voznega parka, od optimizacije poti do upravljanja voznikov, dvigovanja učinkovitosti in operativne inteligence.",
  cta: "Nadgradite svoje prevozne in logistične dejavnosti z Cargobitejevim TMS",
  cta_body: "Pridružite se družini Cargobite in izkoristite moč našega TMS, da odklenete brezprimerne učinkovitosti v svojih logističnih operacijah. Naš TMS paket ne le poenostavlja vaše delovne postopke, temveč vam omogoča, da razširite svoje dejavnosti, povečate dobičkonosnost in vzpostavite močnejše, bolj zanesljive odnose v dobavni verigi. S Cargobitejem ne upravljate samo logistike - postavljate nove mejnike v prevozniškem sektorju."
}

const langTMS = { en, si }

export default langTMS